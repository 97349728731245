import { disableBodyOverflow } from '@utils/disableBodyOverflow';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';

import { EVENTS } from '@/constants/events';
import { EventEmitter } from '@/core/event';
import { warn } from '@/utils/logger';

import App from './App.svelte';

function checkIfContainerExists(elementId: string): boolean {
  return !!document.getElementById(elementId);
}

export default function (
  language: string,
  userSetCountryUrl: string,
  isClosable: boolean,
  events: EventEmitter,
  needToUpdateState: boolean,
) {
  const userCountryNotSetId = 'wows-user-country-not-set';

  if (checkIfContainerExists(userCountryNotSetId)) {
    warn('User country not set window is already exists', userCountryNotSetId);
    return false;
  }

  events.emit(EVENTS.OVERLAY_VISIBILITY, { visible: true });

  const app = new App({
    target: document.body,
    props: {
      userCountryNotSetId,
      language,
      closeApplication,
      isClosable,
      userSetCountryUrl,
    },
  });

  // ESC button is drawn by the Game,
  // so if we need to hide the button, we call this method (false - show ESC button)
  if (needToUpdateState) {
    updateBrowserControlState(isClosable);
  }

  function closeApplication() {
    disableBodyOverflow(false);
    if (needToUpdateState) {
      updateBrowserControlState(false);
    }

    events.emit(EVENTS.OVERLAY_VISIBILITY, { visible: false });
    app.$destroy();
  }
}
