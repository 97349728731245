import { sendAction } from '@/core/action';
import { log } from '@/utils/logger';
import WinStoreCallbackObserver, { WinStoreCallbacks } from '@/platform/WinStore/WinStoreCallbackObserver';


export interface WinStoreSuccessTransaction {
  transactionId: string;
  inAppOfferToken: string;
}

export interface WinStoreFailedTransaction {
  error: string;
  code: string;
}

export function initWinStoreTransaction(productId: string, inAppOfferToken: string, price: string, currencyCode: string): Promise<WinStoreSuccessTransaction | WinStoreFailedTransaction> {
  return new Promise<WinStoreSuccessTransaction | WinStoreFailedTransaction>((resolve, reject) => {
    WinStoreCallbackObserver.once(WinStoreCallbacks.OnPurchaseSucceed, (transactionId, inAppOfferToken) => {
      log('Resolve initWinStoreTransaction method', transactionId, inAppOfferToken);

      resolve({
        transactionId,
        inAppOfferToken,
      });
    });

    WinStoreCallbackObserver.once(WinStoreCallbacks.OnPurchaseFailed, (error, code) => {
      log('Reject initWinStoreTransaction method', error, code);

      reject({
        error,
        code,
      });
    });

    sendAction({
      actionId: 'initWinStoreTransaction',
      productId,
      inAppOfferToken,
      price,
      currencyCode,
    })
  });
}
