import * as React from 'react';

import { ComponentProps } from '@/components/Component';

export interface IProps extends ComponentProps {
    id: string;
    children: React.ReactNode;
}

const StickyContainer = (props: IProps) => (
    <div className={props?.className || ''} id={props.id}>
        {props.children}
    </div>
);

export default StickyContainer;
