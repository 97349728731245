import { error } from '@utils/logger';
import safeJSONParse from '@utils/safeJSONParse';

import { Nullable } from '@/interfaces/Nullable';

interface LocalStorageState<T> {
  expiry: string;
  value: Nullable<T>;
}

class LocalStorage {
  set(name: string, value: any, expiry: string | null = null) {
    const data = { value, expiry };
    try {
      localStorage.setItem(name, JSON.stringify(data));
    } catch (e) {
      error('localStorage error', e);
    }
  }

  get(name: string) {
    const data = localStorage.getItem(name);
    if (!data) {
      return null;
    }

    const json = <LocalStorageState<any>>safeJSONParse(data) || null;
    if (!json) {
      return null;
    }

    const now = Date.now();

    if (json.expiry) {
      const expiry = /^[0-9]+$/.test(json.expiry) ? +json.expiry : json.expiry;
      const expiryTime = new Date(expiry).getTime();
      if (now > expiryTime) {
        this.remove(name);
        return null;
      }
    }

    return json.value;
  }

  remove(name: string) {
    localStorage.removeItem(name);
  }
}

const localStorageHelper = new LocalStorage();

export default localStorageHelper;
