import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './HelperIcon.module.scss';

class Icon extends React.PureComponent<ComponentProps, any> {
    public render() {
        return <i className={classNames(styles.Icon, this.props.className)} />;
    }
}

export default Icon;
