import classNames from 'classnames';
import * as React from 'react';

import Button, { ICustomizeProps } from '../Button/Button';
import Gold from '../Gold/Gold';
import Icon from '../Icon/Icon';
import styles from './ButtonDublon.module.scss';

export interface IProps extends ICustomizeProps {
    caption: string;
    amount: number;
    isDiscount?: boolean;
    onClick?: (event: React.MouseEvent) => void;
}

class ButtonDublon extends React.PureComponent<IProps, any> {
    public render() {
        const { caption, amount, isDiscount, onClick, className, ...customize } = this.props;

        const classNameCaption = classNames(styles.caption, {
            [styles.isDisabled]: !!this.props.isDisabled,
        });

        const classNameDublon = classNames(styles.dublon, {
            [styles.isDisabled]: !!this.props.isDisabled,
        });

        const classNameDiscount = classNames(styles.discount, {
            [styles.isDisabled]: !!this.props.isDisabled,
        });

        return (
            <Button {...customize} onClick={onClick || (() => null)} className={classNames(className)}>
                <span className={classNameCaption}>{caption}</span>
                {isDiscount ? (
                    <span className={classNameDiscount}>
                        <Icon glyph="discount" />
                    </span>
                ) : null}
                <span className={classNameDublon}>
                    <Gold amount={amount} />
                </span>
            </Button>
        );
    }
}

export default ButtonDublon;
