import { sendAsyncAction, AsyncResponse } from '@/core/async';


export function replyToFriendMessageByIndex(index: number, msg: string): Promise<AsyncResponse<void>> {
  return sendAsyncAction({
    actionId: 'replyToSteamFriend',
    index,
    msg,
  });
}
