export const PREPARE_NEXT_ACTION_CODES = {
  PAY: 'pay',
  REDIRECT: 'redirect',
  CHOOSE_PAYMENT_GROUP: 'choose_payment_group',
  CREATE_BINDING: 'create_binding',
};

export type PrepareNextActionCode = typeof PREPARE_NEXT_ACTION_CODES[keyof typeof PREPARE_NEXT_ACTION_CODES];

export const COMMIT_NEXT_ACTION_CODES = {
  THREE_DS_REQUIRED: '3ds_required',
};

export type CommitNextActionCode = typeof COMMIT_NEXT_ACTION_CODES[keyof typeof COMMIT_NEXT_ACTION_CODES];
