import { sendCommand } from '@/core/command';


export function errorNotification(name: string, message: string) {
  return sendCommand({
    command: 'action',
    params: {
      hasHeader: true,
      header: name,
      message,
    },
  });
}
