import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import ClanName from '../ClanName/ClanName';
import styles from './Clan.module.scss';

export interface IProps extends ComponentProps {
    clanTag: string;
    clanColor: string;
    clanName: string;

    disableTooltip?: boolean;
    highlight?: string;
    isOwn: boolean;
    isDisbanded?: boolean;
}

class Clan extends React.PureComponent<IProps> {
    public render() {
        const classNameClan = classNames(styles.clan, {
            [styles.isOwn]: this.props.isOwn,
            [styles.isDisbanded]: this.props.isDisbanded,
        });

        return (
            <div className={styles.container}>
                <div className={classNameClan}>
                    <ClanName
                        clanTag={this.props.clanTag}
                        clanColor={this.props.clanColor}
                        clanName={this.props.clanName}
                        highlight={this.props.highlight}
                    />
                </div>
            </div>
        );
    }
}

export default Clan;
