export const PREPARE_TSV_METHODS = {
  OTP: 'tsv.otp',
  PASSWORD: 'password',
  WGNPS: 'tsv.wgnps',
};

export type PrepareTsvMethod = typeof PREPARE_TSV_METHODS[keyof typeof PREPARE_TSV_METHODS];

export const COMMIT_TSV_METHODS = {
  OTP: 'otp',
  BACKUP: 'backup',
  WGNPS: 'wgnps',
};

export type CommitTsvMethod = typeof COMMIT_TSV_METHODS[keyof typeof COMMIT_TSV_METHODS];

export const TSV_METHODS_MAP = {
  [PREPARE_TSV_METHODS.OTP]: COMMIT_TSV_METHODS.OTP,
  [PREPARE_TSV_METHODS.WGNPS]: COMMIT_TSV_METHODS.WGNPS,
};
