import { disableBodyOverflow } from '@utils/disableBodyOverflow';
import { warn } from '@utils/logger';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';

import { EVENTS } from '@/constants/events';
import { EventEmitter } from '@/core/event';

import App from './App.svelte';

function checkIfContainerExists(elementId: string): boolean {
  return !!document.getElementById(elementId);
}

export default function (
  language: string,
  country: string,
  spaCountry: string,
  userSupportUrl: string,
  isClosable: boolean,
  events: EventEmitter,
  needToUpdateState: boolean,
) {
  const purchaseRestrictionId = 'wows-purchase-restriction';

  if (checkIfContainerExists(purchaseRestrictionId)) {
    warn('Purchase restriction window is already exists', purchaseRestrictionId);

    return false;
  }

  events.emit(EVENTS.OVERLAY_VISIBILITY, { visible: true });

  const app = new App({
    target: document.body,
    props: {
      purchaseRestrictionId,
      isClosable,
      language,
      country,
      spaCountry,
      userSupportUrl,
      closeApplication,
    },
  });

  warn(`Closable set as ${isClosable}`);

  // ESC button is drawn by the Game,
  // so if we need to hide the button, we call this method (false - show ESC button)
  if (needToUpdateState) {
    updateBrowserControlState(isClosable);
  }

  function closeApplication() {
    disableBodyOverflow(false);
    if (needToUpdateState) {
      updateBrowserControlState(false);
    }

    events.emit(EVENTS.OVERLAY_VISIBILITY, { visible: false });
    app.$destroy();
  }
}
