import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Dialog.module.scss';

export interface IProps extends ComponentProps {
    children: React.ReactNode;
}

const DialogContainer = React.memo((props: IProps) => (
    <div className={classNames(styles.container, props.className)}>{props.children}</div>
));

export default DialogContainer;
