import { disableBodyOverflow } from '@utils/disableBodyOverflow';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';

import { EVENTS } from '@/constants/events';
import { EventEmitter } from '@/core/event';

import App from './App.svelte';

export default function (isClosable: boolean, events: EventEmitter, needToUpdateState?: boolean) {
  events.emit(EVENTS.OVERLAY_VISIBILITY, { visible: true });

  const app = new App({
    target: document.body,
    props: {
      isClosable,
      closeApplication,
    },
  });

  function closeApplication() {
    events.emit(EVENTS.OVERLAY_VISIBILITY, { visible: false });

    disableBodyOverflow(false);

    if (needToUpdateState) {
      updateBrowserControlState(false);
    }

    app.$destroy();
  }

  events.subscribe(EVENTS.CLOSE_FASTGOLD_OVERLAY, closeApplication);

  if (needToUpdateState) {
    updateBrowserControlState(isClosable);
  }
}
