import { sendAsyncAction, AsyncResponse } from '@/core/async';


export interface EpicStoreAccountId {
  accountId: string,
}

export function getEpicStoreAccountId(): Promise<AsyncResponse<EpicStoreAccountId>> {
  return sendAsyncAction({
    actionId: 'getEpicAccountInfo',
  });
}
