import { sendAction } from '@/core/action';
import { updateBrowserControlState } from '@/browser/updateBrowserControlState';


export function openShipPreviewInPort(shipId: number, exteriorId?: number, showTTX?: boolean) {
  updateBrowserControlState(true);
  sendAction({
    actionId: 'showShipPreview',
    shipId,
    exteriorId,
    showTTX,
  });
}
