import * as React from 'react';

interface IPropsBody {
    content: any;
}

class TooltipInnerBody extends React.Component<IPropsBody> {
    shouldComponentUpdate(nextProps: IPropsBody) {
        return this.props.content !== nextProps.content;
    }

    public render() {
        return this.props.content;
    }
}

export default TooltipInnerBody;
