import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Tooltip.module.scss';

export interface IProps extends ComponentProps {
    children?: React.ReactNode;
    withMouseClick?: boolean;
    bgColor?: 'dark';
}

const TooltipBody = (props: IProps) => {
    const classNameBody = classNames(
        styles.body,
        props.bgColor && styles[props.bgColor],
        {
            [styles.withMouseClick]: props.withMouseClick,
        },
        props.className,
    );

    return <div className={classNameBody}>{props.children}</div>;
};

export default TooltipBody;
