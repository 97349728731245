import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';
import { thousands } from '~/helpers/formatting';

import Highlighter from '../Highlighter/Highlighter';
import styles from './ClanResource.module.scss';

export interface IProps extends ComponentProps {
    amount?: number;
    isMuted?: boolean;
    isFlat?: boolean;
    isNotEnough?: boolean;
}

class ClanResource extends React.PureComponent<IProps, any> {
    public render() {
        const classClanResource = classNames(styles.resource, {
            [styles.isMuted]: !!this.props.isMuted,
            [styles.isNotEnough]: !!this.props.isNotEnough,
            [styles.isFlat]: !!this.props.isFlat,
        });

        return (
            <Highlighter
                className={classNames(classClanResource, this.props.className)}
                value={thousands(this.props.amount)}
            />
        );
    }
}

export default ClanResource;
