import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './ClanRole.module.scss';

export interface IClanRole extends ComponentProps {
    role: string;
    isLarger?: boolean;
}

const ClanRole = React.memo((props: IClanRole) => (
    <i
        className={classNames(styles[props.role], props.className, {
            [styles.isLarger]: props.isLarger,
        })}
    />
));

export default ClanRole;
