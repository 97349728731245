import { sendCommand } from '@/core/command';
import { safeJSONParse } from '@/utils/safeJSONParse';
import { log, error } from '@/utils/logger';


export enum AsyncResponseStatuses {
  SUCCESS = 0,
  ERROR = 1,
  CANCELLED = 2
}

export interface AsyncParams {
  [key: string]: number | string;
}

export interface AsyncResponse<T> {
  status: AsyncResponseStatuses;
  data: T;
}

export type AsyncRequestResolver = (payload: AsyncResponse<any>) => void;

export interface AsyncRequests {
  [key: string]: {
    resolver: AsyncRequestResolver;
    rejecter: AsyncRequestResolver;
  };
}

export interface JsHostAsyncResponse {
  status?: number,
  actionId: string,
  callbackRequestId?: string,
  data?: object,
}

if (!window.webAsyncRequests) {
  log('Create webAsyncRequests state object');

  window.webAsyncRequests = {};
} else {
  log('webAsyncRequests state object already exists');
}

export function sendAsyncAction<T = any>(asyncParams: AsyncParams): Promise<AsyncResponse<T>> {
  let params: AsyncParams = {
    ...asyncParams,
  };

  if (!params.callbackRequestId) {
    params.callbackRequestId = `${params.actionId}_${Date.now()}_${Math.random()}`;
  }

  if (window.webAsyncRequests[params.callbackRequestId]) {
    error('Callback already exists', params.callbackRequestId, Object.keys(window.webAsyncRequests));

    throw new Error('Callback already exists');
  }

  return new Promise((resolver: AsyncRequestResolver, rejecter: AsyncRequestResolver) => {
    window.webAsyncRequests[params.callbackRequestId] = {
      resolver,
      rejecter,
    };

    log('Calling async action', params);

    sendCommand({
      command: 'action',
      params,
    });
  });
}

function webAsyncJsHostActionsResponseCallback({ status, callbackRequestId, data }: any) {
  if (!callbackRequestId) {
    error('Param callbackRequestId was not provided by client', 'status:', status, 'data:', data);
    return;
  }

  if (!window.webAsyncRequests[callbackRequestId]) {
    error(
      `callbackRequestId ${callbackRequestId} not registered at webAsyncRequests`,
      'Status:',
      status,
      'Data:',
      data,
      'Registered callbackRequestId:',
      JSON.stringify(Object.keys(window.webAsyncRequests)),
      window.webAsyncRequests,
    );
    return;
  }

  switch (status) {
    case AsyncResponseStatuses.SUCCESS: {
      log('Resolve status has been received');

      window.webAsyncRequests[callbackRequestId].resolver({
        status,
        data,
      });

      break;
    }

    case AsyncResponseStatuses.ERROR:
    case AsyncResponseStatuses.CANCELLED:
    default: {
      log('Reject status has been received');

      window.webAsyncRequests[callbackRequestId].rejecter({
        status,
        data: null,
      });

      break;
    }
  }

  log('Removing callback from webAsyncRequests', callbackRequestId, window.webAsyncRequests);
  delete window.webAsyncRequests[callbackRequestId];
}

if (!window.webJsHostActionsListeners) {
  log('Init jsHostActionsListeners as an empty array');

  window.webJsHostActionsListeners = [];
}

window.webJsHostActionsListeners.push(webAsyncJsHostActionsResponseCallback);

if (!window.jsHostActionsResponseCallback) {
  log('Register a new jsHostActionsResponseCallback');

  window.jsHostActionsResponseCallback = (text: string) => {
    const response = <JsHostAsyncResponse>safeJSONParse(text);
    log('Push data to all async callbacks', Object.keys(window.webJsHostActionsListeners).length, response);

    window.webJsHostActionsListeners.forEach((listener) => {
      listener(response);
    });
  }
}
