import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import Spinner from '../Spinner/Spinner';
import styles from './ButtonGroup.module.scss';

export interface IProps extends ComponentProps {
    children: React.ReactNode;
    isLoading?: boolean;
    spinnerLabel?: string;
    textAlign?: string;
}

class ButtonGroup extends React.PureComponent<IProps> {
    public render() {
        const isLoading = !!this.props.isLoading;

        return (
            <div className={classNames(styles.group, styles[this.props.textAlign || ''], this.props.className)}>
                {isLoading ? (
                    <div className={styles.wrapper}>
                        <div className={styles.spinner}>
                            <Spinner label={this.props.spinnerLabel} />
                        </div>
                    </div>
                ) : (
                    <div className={styles.wrapper}>
                        {React.Children.map(this.props.children, (item, index) => {
                            return (
                                <div key={`ButtonGroup_${index}`} className={styles.button}>
                                    {item}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

export default ButtonGroup;
