import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Divider.module.scss';

export interface IProps extends ComponentProps {
    type: 'major' | 'major_light' | 'minor' | 'dashed';
}

const Divider = React.memo((props: IProps) => <div className={classNames(styles[props.type], props.className)} />);

export default Divider;
