import { PLATFORMS } from '@/constants/platforms';
import { CategoryEntity } from '@/core/entity/category.entity';
import { ProductEntity } from '@/core/entity/product.entity';
import { UserEntity } from '@/core/entity/user.entity';
import { VariablePriceEntity } from '@/core/entity/varuablePrice.entity';
import { getCategories } from '@/core/platform/Web/categories';
import { checkout } from '@/core/platform/Web/checkout';
import { getProducts } from '@/core/platform/Web/products';
import { getUser } from '@/core/platform/Web/user';
import { Nullable } from '@/interfaces/Nullable';
import { PlatformProvider } from '@/interfaces/PlatformProvider';
import { CheckoutResult, ProductCustomFields } from '@/interfaces/Product';
import { getProductPrice } from '@/store/product_price';

export class WebPlatform implements PlatformProvider {
  products(customStorefrontName?: string): Promise<ProductEntity[]> {
    return getProducts(customStorefrontName);
  }

  categories(customStorefrontName?: string): Promise<CategoryEntity[]> {
    return getCategories(customStorefrontName);
  }

  checkout(
    externalProductId: string,
    quantity?: number,
    customStorefrontName?: string,
    customFields?: ProductCustomFields,
  ): Promise<CheckoutResult> {
    return checkout(externalProductId, quantity, customStorefrontName, customFields);
  }

  user(force: boolean = false): Promise<UserEntity> {
    return getUser(force);
  }

  isAccountCompleted(): Promise<boolean> {
    return Promise.resolve(false);
  }

  getProductVariablePrice(
    productCode: string,
    quantity: number,
    customStorefrontName?: string,
  ): Promise<Nullable<VariablePriceEntity>> {
    return getProductPrice(productCode, quantity, PLATFORMS.WEB, customStorefrontName);
  }
}
