export function memoizeAsync(name: string, fn) {
  const cache = new Map();

  return (...args) => {
    const cacheKey = `${name}_${JSON.stringify(args)}`;

    if (cache.has(cacheKey)) {
      return cache.get(cacheKey);
    }

    const promise = fn(...args).catch((err) => {
      cache.delete(cacheKey);

      throw err;
    });

    cache.set(cacheKey, promise);

    return promise;
  };
}
