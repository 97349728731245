import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';
import { t } from '~/helpers/localization';

import ClanName from '../ClanName/ClanName';
import styles from './TooltipClan.module.scss';

export interface IProps extends ComponentProps {
    clanTag: string;
    clanColor: string;
    clanName: string;
    isDisbanded?: boolean;
}

class TooltipClan extends React.PureComponent<IProps> {
    public render() {
        const disbandedMessage = this.props.isDisbanded ? (
            <div className={styles.disbandedText}>{t('Клан распущен')}</div>
        ) : null;

        return (
            <div className={classNames(styles.container, this.props?.className)}>
                <div>
                    <ClanName
                        clanTag={this.props.clanTag}
                        clanColor={this.props.clanColor}
                        clanName={this.props.clanName}
                    />
                </div>
                {disbandedMessage}
            </div>
        );
    }
}

export default TooltipClan;
