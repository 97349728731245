import { PURCHASE_ERROR_CODES } from '@/constants/purchaseErrorCodes';
import { t } from '@/i18n';

export const TSV_METHOD_ERROR_CODES = {
  TSV_METHOD_NOT_ENABLED: 'TSV_METHOD_NOT_ENABLED',
  TSV_INVALID_OTP_CODE: 'TSV_INVALID_OTP_CODE',
  TSV_OTP_CODE_EXPIRED: 'TSV_OTP_CODE_EXPIRED',
};

export const TSV_METHOD_ERROR_CODES_TEXT = {
  [PURCHASE_ERROR_CODES.TSV_INVALID_OTP_CODE]: t('Введен неверный код'),
  [PURCHASE_ERROR_CODES.TSV_OTP_CODE_EXPIRED]: t('Время для ввода кода истекло. Необходимо использовать новый код.'),
};
