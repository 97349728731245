import { isIngame } from '@wg/web2clientapi/utils/checkIngame';
export const PLATFORMS = {
  WEB: 'ingameShop',
  STEAM: 'steam',
  WIN_STORE: 'winstore',
  EGS: 'egs',
  VK: 'vkp',
};

export type Platform = typeof PLATFORMS[keyof typeof PLATFORMS];

export const DWH_PLATFORMS = {
  [PLATFORMS.WEB]: isIngame() ? 'ingameShop' : 'web',
  [PLATFORMS.STEAM]: 'steam',
  [PLATFORMS.WIN_STORE]: 'winstore',
  [PLATFORMS.EGS]: 'egs',
  [PLATFORMS.VK]: 'vkp',
};
