import { sendAsyncAction, AsyncResponse } from '@/core/async';


export enum SteamUserRestrictions {
  NONE = 0,
  UNKNOWN = 1,
  ANY_CHAT = 2,
  VOICE_CHAT = 4,
  GROUP_CHAT = 8,
  RATING = 16,
  GAME_INVITES = 32,
  TRADING = 64,
}

export function getUserRestrictions(): Promise<AsyncResponse<SteamUserRestrictions>> {
  return sendAsyncAction({
    actionId: 'getUserRestrictions',
  });
}
