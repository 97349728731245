import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import Button, { ButtonType } from '../Button/Button';
import styles from './ButtonIcon.module.scss';

export type ButtonIconType = 'info' | 'clan-info' | 'check' | 'cross' | 'plus';

export interface IProps extends ComponentProps {
    type: ButtonIconType;
    buttonType?: ButtonType;
    isFlat?: boolean;
    isDisabled?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    tooltipContent?: React.ReactNode;
}

const ButtonIcon = (props: IProps) => {
    const renderIcon = () => {
        switch (props.type) {
            case 'info': {
                return <div className={styles.infoIcon} />;
            }
            case 'clan-info': {
                return <div className={styles.clanInfoIcon} />;
            }
            case 'check': {
                return <div className={styles.checkIcon} />;
            }
            case 'cross': {
                return <div className={styles.crossIcon} />;
            }
            case 'plus': {
                return <div className={styles.plusIcon} />;
            }

            default: {
                return <div className={styles.icon} />;
            }
        }
    };

    return (
        <Button
            isIcon
            isFlat={props.isFlat}
            isDisabled={props.isDisabled}
            tooltipContent={props.tooltipContent}
            type={props.buttonType}
            onClick={props.onClick}
            className={classNames(props.className)}
        >
            {renderIcon()}
        </Button>
    );
};

export default React.memo(ButtonIcon);
