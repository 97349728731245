import {
  DEPENDS_ON_REALM,
  PURCHASE_ERROR_CODES_TEXT,
  PURCHASE_ERROR_CODES_TEXT_FOR_CIS,
} from '@/constants/purchaseErrorCodes';
import { Nullable } from '@/interfaces/Nullable';

export default function getPurchaseErrorCodeText(errorCode: string, isCISReam: boolean): Nullable<string> {
  if (!PURCHASE_ERROR_CODES_TEXT.hasOwnProperty(errorCode)) {
    return null;
  }

  if (!DEPENDS_ON_REALM.includes(errorCode)) {
    return PURCHASE_ERROR_CODES_TEXT[errorCode];
  }

  return isCISReam ? PURCHASE_ERROR_CODES_TEXT_FOR_CIS[errorCode] : PURCHASE_ERROR_CODES_TEXT[errorCode];
}
