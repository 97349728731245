import { sendAsyncAction, AsyncResponse } from '@/core/async';


export enum SteamFriendStatus {
  OFFLINE = 0,
  AWAY = 1,
  IN_GAME = 2,
  ONLINE = 3,
  SLEEP = 4,
}

export interface SteamFriend {
  index: number;
  steamId: string;
  nickname: string;
  status: SteamFriendStatus,
}

export function getFriendsList(): Promise<AsyncResponse<SteamFriend[]>> {
  return sendAsyncAction({
    actionId: 'getFriendsList',
  });
}
