function joinWithBackendUrl(url: string, params: any = {}) {
  return params.backendUrl ? `${params.backendUrl}${url}` : url;
}

export function buildUrl(urlWithParams: string, params: any = {}) {
  const url = urlWithParams.replace(/\/{(\w+)}/g, (match, key: string) => {
    return params[key] ? `/${params[key]}` : '';
  });

  return joinWithBackendUrl(url, params);
}

export function addSearchParamsToUrl(url, params: Record<string, any>) {
  const searchParams = Object.keys(params).reduce((state, key) => {
    state.push(`${key}=${params[key]}`);
    return state;
  }, []);

  return `${url}?${searchParams.join('&')}`;
}
