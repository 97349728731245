import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import Tooltip from '../Tooltip/Tooltip';
import TooltipBody from '../Tooltip/TooltipBody';
import { default as TD } from '../Tooltip/TooltipDecorator';
import styles from './Realm.module.scss';

export interface IRealm extends ComponentProps {
    realm: string;
    realmMap: {
        [key: string]: {
            shortName: string;
            name: string;
        };
    };
}

export interface RealmElProps extends ComponentProps {
    shortName: string;
}

function RealmElClass(props: RealmElProps) {
    return (
        <div className={classNames(styles.container, props.className)}>
            <div>{props.shortName}</div>
        </div>
    );
}

const RealmEl = TD()(RealmElClass);

const Realm = React.memo((props: IRealm) => {
    if (!props.realmMap[props.realm]) {
        return <div className={styles.container}>{props.realm}</div>;
    }

    const shortName = props.realmMap[props.realm].shortName;

    return (
        <RealmEl
            className={props.className}
            shortName={shortName}
            tooltipBody={
                <Tooltip>
                    <TooltipBody>{props.realmMap[props.realm].name}</TooltipBody>
                </Tooltip>
            }
        />
    );
});

export default Realm;
