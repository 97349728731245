import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import TooltipInnerBody from './TooltipInnerBody';
import styles from './TooltipProviderStyles.module.scss';

export const tooltipUniqueId = 'wows-react-tooltip-body';
export const windowPadding = 20;

interface IProps extends ComponentProps {
    children?: React.ReactNode;
}

interface IState {
    isTooltipVisible?: boolean;
    body: HTMLElement | HTMLDivElement | any;
    position: {
        x: number;
        y: number;
    };
}

class TooltipWrapper extends React.Component<IProps, IState> {
    public needHorisontalFlip = false;
    public needVerticalFlip = false;

    public Wrapper: HTMLDivElement;

    public state: IState = {
        isTooltipVisible: false,
        body: null,
        position: {
            x: 0,
            y: 0,
        },
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState) {
        return (
            this.state.isTooltipVisible != nextState.isTooltipVisible ||
            this.state.position.x !== nextState.position.x ||
            this.state.position.y !== nextState.position.y
        );
    }

    public _setRef = (ref: HTMLDivElement) => {
        if (ref) {
            this.Wrapper = ref;
        }
    };

    public _checkPosition() {
        const offset: {
            marginLeft?: number;
            marginTop?: number;
        } = {};

        if (this.Wrapper) {
            const element = document.getElementById(tooltipUniqueId);

            if (element) {
                const width = element.offsetWidth;
                const height = element.offsetHeight;
                const fullWidth = window.innerWidth;
                const fullHeight = window.innerHeight;
                const rightOffset = fullWidth - this.state.position.x - width - windowPadding;
                const bottomOffset = fullHeight - this.state.position.y - height - windowPadding;

                if (rightOffset <= 0) {
                    offset.marginLeft = rightOffset;
                }

                if (bottomOffset <= 0) {
                    offset.marginTop = -(-bottomOffset + windowPadding);
                }
            }
        }

        return offset;
    }

    public render() {
        return this.state.isTooltipVisible ? (
            <div
                id={tooltipUniqueId}
                ref={this._setRef}
                className={classNames(styles.TooltipBody, this.props?.className)}
                style={{
                    top: this.state.position.y,
                    left: this.state.position.x,
                    ...this._checkPosition(),
                }}
                key={tooltipUniqueId}
            >
                {this.state.body ? <TooltipInnerBody content={this.state.body} /> : null}
            </div>
        ) : null;
    }
}

export default TooltipWrapper;
