import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './LabelStyles.module.scss';

export interface IProps extends ComponentProps {
    children: React.ReactNode;
}

const Label = React.memo((props: IProps) => (
    <div className={classNames(styles.label, props.className)}>{props.children}</div>
));

export default Label;
