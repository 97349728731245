import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Tooltip.module.scss';

export interface IProps extends ComponentProps {
    children?: React.ReactNode;
    isHelpIcon?: boolean;
    isBold?: boolean;
}

const TooltipHeader = (props: IProps) => {
    const classNameHeader = classNames(
        styles.header,
        {
            [styles.isHelpIcon]: props.isHelpIcon,
            [styles.isBold]: props.isBold,
        },
        props.className,
    );

    return <div className={classNameHeader}>{props.children}</div>;
};

export default TooltipHeader;
