import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';
import { thousands } from '~/helpers/formatting';

import styles from './Gold.module.scss';

export interface IProps extends ComponentProps {
    amount: number;
    isFlat?: boolean;
}

const Gold = (props: IProps) => (
    <div className={classNames(styles.gold, props.className, { [styles.isFlat]: !!props.isFlat })}>
        {thousands(props.amount)}
    </div>
);

export default Gold;
