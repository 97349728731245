export const EVENTS = {
  CATEGORIES_UPDATE: 'CATEGORIES_UPDATE',
  PRODUCTS_UPDATE: 'PRODUCTS_UPDATE',
  PRODUCT_UPDATE: 'PRODUCT_UPDATE',
  CHECK_PURCHASE_RESTRICTION: 'CHECK_PURCHASE_RESTRICTION',
  PURCHASE_RESTRICTION_VISIBILITY: 'PURCHASE_RESTRICTION_VISIBILITY',
  OVERLAY_VISIBILITY: 'OVERLAY_VISIBILITY',
  SHOP_UNAVAILABLE: 'SHOP_UNAVAILABLE',
  CHECK_USER_COUNTRY_IS_SET: 'CHECK_USER_COUNTRY_IS_SET',
  ACCOUNT_UPDATE: 'ACCOUNT_UPDATE',
  ACTIVE_PAYMENT_METHOD: 'ACTIVE_PAYMENT_METHOD',
  CHANGE_CURRENT_PRODUCT: 'CHANGE_CURRENT_PRODUCT',
  TSV_MANAGEMENT_URL: 'TSV_MANAGEMENT_URL',
  SET_TSV: 'SET_TSV',
  CLOSE_FASTGOLD_OVERLAY: 'CLOSE_FASTGOLD_OVERLAY',
  SET_FETCHING: 'SET_FETCHING',
};

export type Events = typeof EVENTS[keyof typeof EVENTS];
