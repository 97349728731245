import { ShowcaseProps } from '../shared/components/Showcase';
import App from './App.svelte';

export interface FastGoldShowcaseProps extends ShowcaseProps {
  onBuy?: (event: any) => Promise<void>;
  onShowDetails?: (event: any) => void;
  onOpenMoreBundles?: (event: MouseEvent) => void;
  helpText?: string;
  displayLimit?: number;
}

export default App;

export const mountFastGoldShowcase = (target: Element | ShadowRoot, props: FastGoldShowcaseProps): App => {
  return new App({ target, props });
};
