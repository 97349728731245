import { sendAction } from '@/core/action';


export enum ROUTES {
  sseAll = 'sse_common',
  port = 'port',
  barge = 'barge',

  // All possible but not tested routes from ui_windows.xml
  // If you need any of these just uncomment and test it

  // sse = 'sse',
  // sseShipAces = 'sse_ship_aces',
  // sseNewbieQuests = 'sse_newbie_quests',

  // shipyard = 'shipyard',
  // shipPreview = 'ship_preview',
  // balancer = 'balancer',
  // newbieGifts = 'newbie_gifts',

  // main = 'main',
  // management = 'management',
  // exterior = 'exterior',
  // research = 'research',
  // profile = 'profile',
  // clans = 'clans',

  // leveling = 'leveling',
  // achievements = 'achievements',
  // ranks = 'ranks',
  // ranksCarousel = 'ranks_carousel',
  // ranksRewards = 'ranks_rewards',
  // collections = 'collections',
  // summary = 'summary',

  // camouflage = 'camouflage',
  // signals = 'signals',
  // ensign = 'ensign',
  // navalFlag = 'naval_flag',

  // battleTypeChooserShow = 'battle_type_chooser_show',
  // battleTypeChooserHide = 'battle_type_chooser_hide',

  // patch = 'patch',
  // emblem = 'emblem',
  // symbol = 'symbol',
  // background = 'background',

  // graphics = 'graphics',
  // sound = 'sound',
  // controls = 'controls',
  // portal = 'portal',

  // campaigns = 'campaigns',
  // campaignsChooseCampaign = 'campaigns_choose_campaign',
  // campaignsChooseMission = 'campaigns_choose_mission',
  // campaignsChooseTask = 'campaigns_choose_task',
}

export function navigateTo(routeId: ROUTES) {
  return sendAction({
    actionId: 'navigateTo',
    routeId,
  });
}
