import DwhExport from '@wg/dwh-export-lib';

import { DwhEvent } from '@/constants/dwhEvents';
import { DWH_PLATFORMS, Platform } from '@/constants/platforms';
import { Nullable } from '@/interfaces/Nullable';
import { ObjectLiteral } from '@/interfaces/ObjectLiteral';
import { Settings } from '@/settings';

export type DwhEventName = DwhEvent;
export type DwhEventPayload = ObjectLiteral<any>;

let isInitialized: boolean = false;
let accountId: Nullable<string> = null;
let dwhExport: Nullable<DwhExport> = null;
const defaultMeta = {
  sid: null,
  pl: null,
  page: null,
};

function createSessionId() {
  return Array(20)
    .fill(1)
    .map(() => Math.floor(Math.random() * 36).toString(36))
    .join('');
}

function clearMeta(meta: DwhEventPayload) {
  const cleanedMeta = {
    ...meta,
  };

  for (const key in meta) {
    if (meta[key] === undefined || meta[key] === null) {
      delete meta[key];
    } else if (meta[key] && typeof meta[key] !== 'number' && typeof meta[key].toString === 'function') {
      meta[key] = meta[key].toString();
    }
  }

  return cleanedMeta;
}

function joinWithDefaults(meta: DwhEventPayload = {}): DwhEventPayload {
  return clearMeta({
    ...defaultMeta,
    ...meta,
  });
}

export function createAnalytics(settings: Settings, platform: Platform, id: string): void {
  if (id) {
    accountId = id;
  }

  defaultMeta.sid = createSessionId();
  defaultMeta.pl = DWH_PLATFORMS[platform] || platform;
  defaultMeta.page = settings.storefrontName;

  dwhExport = new DwhExport({
    url: settings.dwhExportUrl,
    exportInterval: settings.analytics.sendInterval,
    maxEventsBatchSize: settings.analytics.maxBatchSize,
    project: settings.analytics.projectName,
  });
  dwhExport.start();

  isInitialized = true;
}

export function addEvent(eventName: DwhEventName, meta?: DwhEventPayload, forcePush?: boolean): void {
  if (!isInitialized) {
    return;
  }

  if (!accountId) {
    return;
  }

  dwhExport.push(eventName, joinWithDefaults(meta));

  if (forcePush) {
    dwhExport.send();
  }
}
