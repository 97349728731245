import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Tooltip.module.scss';

export interface IProps extends ComponentProps {
    children?: React.ReactNode;
    mouseIcon: 'left' | 'right';
}

const TooltipFooter = (props: IProps) => {
    const classNameFooter = classNames(styles.footer, styles[props.mouseIcon], props.className);

    return <div className={classNameFooter}>{props.children}</div>;
};

export default TooltipFooter;
