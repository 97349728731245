import * as React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import { ComponentProps } from '@/components/Component';

export interface StepCSSTransitionGroupProps extends ComponentProps {
    level: number;
    children: React.ReactNode;
    id?: string;
}

const transitionAppearTimeout = 0;
const component = 'div';
const transitionEnter = false;
const transitionLeave = false;

class StepCSSTransitionGroup extends React.PureComponent<StepCSSTransitionGroupProps, any> {
    public render() {
        let content = null;
        if (this.props.children !== undefined && this.props.children !== null) {
            content = (
                <ReactCSSTransitionGroup
                    id={this.props.id}
                    className={this.props.className}
                    component={component}
                    transitionName={{
                        appear: 'step',
                        appearActive: `step-active-${this.props.level}`,
                    }}
                    transitionEnter={transitionEnter}
                    transitionLeave={transitionLeave}
                    transitionAppear
                    transitionAppearTimeout={transitionAppearTimeout}
                >
                    {this.props.children}
                </ReactCSSTransitionGroup>
            );
        }

        return content;
    }
}

export default StepCSSTransitionGroup;
