import web2clientSounds from '@wg/web2clientapi/sound';
import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';
import { t } from '~/helpers/localization';

import Label from '../Label/Label';
import Spinner from '../Spinner/Spinner';
import styles from './Input.module.scss';

export interface IProps extends ComponentProps {
    type?: HTMLInputElement['type'];
    name?: HTMLInputElement['name'];
    value: string;

    error?: string;
    help?: string;
    info?: string;
    label?: string;
    maxLength?: number;
    placeholder?: string;
    width?: string;
    noimesupport?: boolean;
    isValidating?: boolean;
    isCounted?: boolean;
    isInlined?: boolean;
    isTextCentered?: boolean;
    isFocusOn?: boolean;
    isDisabled?: boolean;

    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyDownCapture?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    t?: (str: string) => string;
}

class Input extends React.PureComponent<IProps> {
    public _input: any;

    constructor(props: IProps) {
        super(props);
    }

    public componentDidMount() {
        if (this.props.isFocusOn) {
            this._input.focus();
        }
    }

    public onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.onChange) {
            this.props.onChange(event);
        }
        web2clientSounds.playInputSound();
    };

    public inputRef = (c: HTMLInputElement): string => {
        if (this._input && this.props.noimesupport) {
            this._input.setAttribute('noimesupport', '');
        }

        return '';
    };

    public render() {
        const classNameContainer = classNames(styles.container, {
            [styles.isDisabled]: this.props.isDisabled,
        });

        const classNameInner = classNames(styles.inner, {
            [styles.isInlined]: this.props.isInlined,
        });

        const classNameInput = classNames(styles.input, {
            [styles.isErrored]: this.props.error,
            [styles.isTextCentered]: this.props.isTextCentered,
        });

        let renderLabel;
        let renderValidator;
        let renderError;
        let renderHelp;
        let renderInfo;
        let renderCounter = null;

        if (this.props.label) {
            renderLabel = <Label>{this.props.label}</Label>;
        }

        if (this.props.isValidating) {
            renderValidator = (
                <div className={styles.validator}>
                    <Spinner />
                    {t('Проверка:валидация')}
                </div>
            );
        } else if (this.props.error) {
            renderError = <div className={styles.error}>{this.props.error}</div>;
        }

        if (this.props.help) {
            renderHelp = <div className={styles.help}>{this.props.help}</div>;
        }

        if (this.props.info) {
            renderInfo = <div className={styles.info}>{this.props.info}</div>;
        }

        if (this.props.isCounted) {
            const valueLen = this.props.value.length;
            renderCounter = (
                <div className={styles.counter}>
                    <span className={styles.counterCurrent}>{valueLen}</span> / {this.props.maxLength}
                </div>
            );
        }

        return (
            <div className={classNames(classNameContainer, this.props.className)}>
                {renderLabel}
                <div className={classNameInner}>
                    <input
                        className={classNameInput}
                        disabled={this.props.isDisabled}
                        maxLength={this.props.maxLength}
                        placeholder={this.props.placeholder}
                        style={{ width: this.props.width }}
                        type={this.props.type || 'text'}
                        name={this.props.name}
                        value={this.props.value}
                        onBlur={this.props.onBlur}
                        onChange={this.onChange}
                        onFocus={this.props.onFocus}
                        onKeyPress={this.props.onKeyPress}
                        onKeyDownCapture={(e) => {
                            if (this.props.onKeyDownCapture !== undefined && this.props.onKeyDownCapture !== null) {
                                this.props.onKeyDownCapture(e);
                            }
                        }}
                        ref={this.inputRef}
                        spellCheck={false}
                    />
                    {renderValidator}
                    {renderError}
                    {renderHelp}
                    {renderInfo}
                    {renderCounter}
                </div>
            </div>
        );
    }
}

export default Input;
