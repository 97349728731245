import * as React from 'react';
import ReactDOM from 'react-dom';

import { ComponentProps } from '@/components/Component';

export interface IProps extends ComponentProps {
    str: string;
    [key: string]: string | number | React.ReactNode;
}

export interface IState {}

class Interpolate extends React.PureComponent<IProps, IState> {
    public parent: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);
        this.parent = React.createRef<HTMLDivElement>();
    }

    private getId(selector: string, index: number): string {
        return `ipl-${selector}-${index}`;
    }

    public componentDidMount() {
        this.updateContent();
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        this.updateContent();
    }

    public updateContent = () => {
        let i = 0;
        // @ts-ignore
        this.props.str.replace(/%\(\w+\)s/g, (match: any) => {
            i++;
            const selector = match.replace('%(', '').replace(')s', '');
            if (this.props[selector]) {
                const component = this.props[selector];
                const place = this.parent.current?.querySelector(`[data-interpolate="${this.getId(selector, i)}"`);
                if (place && component) {
                    // @ts-ignore
                    ReactDOM.render(component, place);
                }
            }
        });
    };

    public getInnerHtml = () => {
        let i = 0;
        return this.props.str
            .replace(/((?<beforeInsertion>[^\s]{0,})|(?<spaceBeforeInsertion>[\s]{1,}))(?<insertion>%\(\w+\)s)(?<afterInsertion>[^\s]{0,})/g, (...args) => {
                const {
                    beforeInsertion,
                    spaceBeforeInsertion,
                    insertion,
                    afterInsertion
                } = args[args.length - 1]
                i++;
                const selector = insertion.replace('%(', '').replace(')s', '');
                const id = this.getId(selector, i);
                return `<span style="white-space: nowrap">${beforeInsertion || ''}${spaceBeforeInsertion ? '&nbsp;' : ''}<span style="display: inline-block" data-interpolate="${id}"></span>${afterInsertion || ''}</span>`;
            });
    };

    public render() {
        return (
            <span
                className={this.props.className || ''}
                ref={this.parent}
                dangerouslySetInnerHTML={{ __html: this.getInnerHtml() }}
            />
        );
    }
}

export default Interpolate;
