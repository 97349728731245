import browser from '@/browser';
import platforms from '@/platform';
import port from '@/port';
import shipyard from '@/shipyard';
import sound from '@/sound';
import utils from '@/utils';


export default {
  browser,
  platforms,
  port,
  shipyard,
  sound,
  utils,
}
