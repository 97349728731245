export function disableBodyOverflow(disable: boolean): void {
  const hiddenStyle = 'overflow-y:hidden!important;';
  const currentStyle = document.body.getAttribute('style') || '';

  if (disable) {
    document.body.setAttribute('style', `${hiddenStyle}${currentStyle}`);
  } else {
    document.body.setAttribute('style', currentStyle.replace(hiddenStyle, ''));
  }
}
