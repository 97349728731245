import { sendAction } from '@/core/action';

export enum PROJECTS {
  guides = 'guidesInGame',
  inGameNews = 'newsInGame',
  playTogether = 'playTogetherInGame',
  profile = 'playerStats',
  clans = 'clansInGame',
  clanInvites = 'clanInvites',
  clanWars = 'clanWarsInGame',
  warehouse = 'warehouseInGame',
  shop = 'shopInGame',
  metaShop = 'metaShop',
  shipyard = 'shipyardInGame',
  catalogue = 'catalogueInGame',
}

export function goToWebProject(projectId: string, path = '') {
  return sendAction({
    actionId: 'goToWebProject',
    projectId,
    path,
  });
}
