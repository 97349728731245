import { sendAction } from '@/core/action';
import { updateBrowserControlState } from '@/browser/updateBrowserControlState';


export function closePortPreview() {
  updateBrowserControlState(false);
  sendAction({
    actionId: 'hideShipPreview',
  });
}
