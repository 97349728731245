import classnames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Nav.module.scss';

export interface Nav_Route {
    path: string;
    title: string;
    badgeCount?: number;
}

export interface Nav_Props extends ComponentProps {
    root: string;
    routes: Array<Nav_Route>;
    selectedPath: string;
    useLink?: boolean;
    onRouteClick: (route: string) => void;
}

export type Nav = React.FC<Nav_Props>;

export const Nav: Nav = React.memo<Nav>((props: Nav_Props) => {
    const { root, routes } = props;
    const navClass = classnames(styles.nav, props.className);

    const onRouteClick = (route: Nav_Route) => () => {
        const path = `${root}${route.path}`;
        props.onRouteClick(path);
    };

    return (
        <nav className={navClass}>
            {routes.map((route: Nav_Route, index: number) => {
                const path = `${root}${route.path}`;
                const isActive = path === props.selectedPath;
                const navItem = classnames(styles.navItem, {
                    [styles.navItemActive]: isActive,
                });
                const count = route.badgeCount && route.badgeCount > 99 ? `99+` : `${route.badgeCount}`;
                return (
                    <a key={`NavItem-${route.path}`} className={navItem} onClick={onRouteClick(route)}>
                        <span>{route.title}</span>
                        {route.badgeCount ? <div className={styles.badge}>{count}</div> : null}
                    </a>
                );
            })}
        </nav>
    );
});

export default Nav;
