import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Link.module.scss';

export interface IProps extends ComponentProps {
    children: React.ReactChild;
    isActionLink: boolean;
    to?: string;
    arrow: 'up' | 'down' | '';
    onClick: () => void;
}

const Link = React.memo((props: IProps) => {
    const classNameLink = classNames(styles.link, styles[props.arrow], props.className);
    const classNameActionLink = classNames(styles.link, styles.actionLink, styles[props.arrow], props.className);

    return props.isActionLink ? (
        <span className={classNameActionLink} onClick={props.onClick}>
            {props.children}
        </span>
    ) : (
        <a href={props.to} className={classNameLink} onClick={props.onClick}>
            {props.children}
        </a>
    );
});

export default Link;
