import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './LineClamp.module.scss';

export interface IProps extends ComponentProps {
    children: React.ReactNode;
    lines: number;
}

const LineClamp = React.memo((props: IProps) => {
    const style: React.CSSProperties = {
        display: '-webkit-box',
        WebkitLineClamp: props.lines,
        WebkitBoxOrient: 'vertical',
        color: '#eee',
    };

    return (
        <div className={classNames(styles.base, props.className)} style={style}>
            {props.children}
        </div>
    );
});

export default LineClamp;
