import * as React from 'react';
import HeightReporter from 'react-height';
import { Motion, spring } from 'react-motion';

const stringHeight = (height: number) => Math.max(0, parseFloat(`${height}`)).toFixed(1);

export interface IProps {
    isOpened: boolean;
    children: React.ReactNode;
    defaultHeight: number;
    onRest: () => void;
}

export interface IState {
    height: number;
    isOpenedChanged: boolean;
}

class Collapse extends React.PureComponent<IProps, IState> {
    public renderStatic: boolean;
    public height: string;

    constructor(props: IProps) {
        super(props);
        this.state = {
            height: -1,
            isOpenedChanged: false,
        };
    }

    public UNSAFE_componentWillMount() {
        this.height = stringHeight(this.props.defaultHeight);
        this.renderStatic = true;
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        this.setState({
            isOpenedChanged: nextProps.isOpened !== this.props.isOpened,
        });
    }

    public onHeightReady = (height: number) => {
        if (this.renderStatic && this.props.isOpened) {
            this.height = stringHeight(height);
        }

        this.setState({
            height: this.props.isOpened || !this.renderStatic ? height : Math.min(height, this.props.defaultHeight),
        });
    };

    public getMotionHeight(height: number) {
        const newHeight = this.props.isOpened
            ? Math.max(0, parseFloat(`${height}`)).toFixed(1)
            : stringHeight(Math.min(height, this.props.defaultHeight));

        // No need to animate if content is closed and it was closed previously
        // Also no need to animate if height did not change
        const skipAnimation = (!this.state.isOpenedChanged && !this.props.isOpened) || this.height === newHeight;

        const springHeight = spring(
            this.props.isOpened ? Math.max(0, height) : Math.min(height, this.props.defaultHeight),
            {
                precision: 0.5,
                damping: 26,
                stiffness: 200,
            },
        );
        const instantHeight = this.props.isOpened ? Math.max(0, height) : Math.min(height, this.props.defaultHeight);
        return skipAnimation ? instantHeight : springHeight;
    }

    public render() {
        const { isOpened, onRest, defaultHeight } = this.props;

        let style: React.CSSProperties;
        const renderStatic = this.renderStatic;
        const { height } = this.state;
        const currentStringHeight = parseFloat(`${height}`).toFixed(1);

        if (height > -1 && renderStatic) {
            this.renderStatic = false;
        }

        const content = <HeightReporter onHeightReady={this.onHeightReady}>{this.props.children}</HeightReporter>;

        if (renderStatic) {
            style = isOpened ? { height: '100%' } : { overflow: 'hidden', height: defaultHeight };

            if (!isOpened && height > -1) {
                return <div style={{ height: height, overflow: 'hidden' }}>{content}</div>;
            }

            return (
                <Motion
                    defaultStyle={{ height: Math.max(0, height) }}
                    style={{ height: Math.max(0, height) }}
                    onRest={onRest}
                >
                    {() => <div style={style}>{content}</div>}
                </Motion>
            );
        }

        return (
            <Motion
                defaultStyle={{ height: Math.max(defaultHeight, height) }}
                onRest={onRest}
                style={{ height: this.getMotionHeight(height) }}
            >
                {(state) => {
                    this.height = stringHeight(state.height);

                    const style =
                        isOpened && this.height === currentStringHeight
                            ? { height: '100%' }
                            : {
                                  height: state.height,
                                  overflow: 'hidden',
                              };

                    return <div style={style}>{content}</div>;
                }}
            </Motion>
        );
    }
}

export default Collapse;
