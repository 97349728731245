import classNames from 'classnames';
import * as React from 'react';
import { useRef, useState } from 'react';

import { ComponentProps } from '@/components/Component';
import { t } from '~/helpers/localization';

import Input from '../Input/Input';
import styles from './Autocomplete.module.scss';

export interface IProps extends ComponentProps {
    value: string | null;
    width?: string;
    maxLength?: number;
    minLength?: number;
    onChange: (value: string) => void;
    onSearch: (value: string | null) => void;
    onSelect: (item: any) => void;
    onClear: () => void;
    placeholder?: string;
    autocomplete: Array<string>;
    suggestionCount: number;
    renderItem: any;
}

export type IState = {
    isVisible: boolean;
    selectedIndex?: number | null;
};

const Autocomplete: React.FC<IProps> = (props) => {
    const {
        className,
        value,
        width,
        maxLength,
        minLength = 2,
        placeholder,
        renderItem,
        autocomplete,
        suggestionCount,
        onClear,
        onChange,
        onSearch,
        onSelect,
    } = props;
    const [isVisible, setIsVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    let inputRef = useRef<any>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    const blurInput = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.blur();
        }
    };

    const onBlur = () => {
        setIsVisible(false);
        setSelectedIndex(null);
    };

    const onFocus = () => {
        setIsVisible(true);
        menuRef.current && menuRef.current.focus();
    };

    const onKeyPress = (e: React.KeyboardEvent) => {
        switch (e.nativeEvent.keyCode) {
            case 27: {
                onEsc();
                break;
            }

            case 13: {
                onEnter();
                break;
            }

            case 38: {
                onArrowUp();
                break;
            }

            case 40: {
                onArrowDown();
                break;
            }

            default: {
                return;
            }
        }
    };

    const onEsc = () => {
        onBlur();
        blurInput();
    };

    const onEnter = () => {
        onBlur();
        blurInput();

        if (selectedIndex !== null && selectedIndex !== undefined) {
            onSelectInto(selectedIndex);
        } else {
            onSearch(value);
        }
    };

    const onArrowDown = () => {
        const count = suggestionCount ? suggestionCount : 5;
        let newIndex = 0;

        if (selectedIndex) {
            newIndex = selectedIndex + 1;
        }

        if (selectedIndex === null) {
            newIndex = 0;
        }

        if (newIndex === count) {
            newIndex = 0;
        }

        setSelectedIndex(newIndex);
    };

    const onArrowUp = () => {
        const count = suggestionCount ? suggestionCount : 5;
        let newIndex = 0;

        if (selectedIndex) {
            newIndex = selectedIndex - 1;
        }

        if (newIndex < 0) {
            newIndex = count - 1;
        }

        setSelectedIndex(newIndex);
    };

    const onSelectInto = (index: number) => {
        if (autocomplete && autocomplete.length && autocomplete[index] && onSelect) {
            onSelect(autocomplete[index]);
        }
    };

    const onTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
        if (e.target.value.length === 0) {
            onClear();
        }
    };

    const onItemClick = (index: number) => () => {
        onBlur();
        blurInput();
        onSelectInto(index);
    };

    const overlayClick = () => {
        onBlur();
        blurInput();
    };

    const clear = () => {
        onBlur();
        blurInput();
        if (onClear) {
            onClear();
        }
    };

    const renderAutocomplete = () => {
        const autocompleteItems =
            autocomplete && autocomplete.length > 0 ? autocomplete.slice(0, suggestionCount ? suggestionCount : 5) : [];

        if (isVisible && value && value.length < minLength && value.length > 0) {
            return (
                <div className={styles.autocomplete}>
                    <div className={styles.overlay} onClick={overlayClick} />
                    <div className={styles.message}>{t('Введите хотя бы 2 символа для поиска клана.')}</div>
                </div>
            );
        } else if (isVisible && autocompleteItems && autocompleteItems.length > 0) {
            return (
                <div className={styles.autocomplete} ref={menuRef}>
                    <div className={styles.overlay} onClick={overlayClick} />
                    {autocompleteItems.map((item, index) => {
                        const isSelected = index === selectedIndex;
                        return (
                            <div
                                key={`autocomplete-item-${index}`}
                                className={`${styles.item} ${isSelected ? styles.selected : ''}`}
                                onClick={onItemClick(index)}
                            >
                                {renderItem ? renderItem(item) : `${item}`}
                            </div>
                        );
                    })}
                </div>
            );
        }

        return null;
    };

    const isEnterVisible = value && value.length > 1;
    return (
        <div className={classNames(styles.wrapper, className)}>
            <Input
                ref={(r) => {
                    if (r) {
                        inputRef = r._input;
                    }
                }}
                noimesupport
                value={value || ''}
                width={width}
                maxLength={maxLength}
                onChange={onTextInput}
                placeholder={placeholder}
                onFocus={onFocus}
                onKeyDownCapture={onKeyPress}
                isFocusOn={isVisible}
                t={t}
            />
            {renderAutocomplete()}

            {isEnterVisible && <div className={styles.enter} onClick={onEnter} />}
            {isEnterVisible && <div className={styles.clear} onClick={clear} />}
        </div>
    );
};

export default React.memo(Autocomplete);
