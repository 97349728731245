import { openExternalUrl } from '@wg/web2clientapi/browser/openExternalUrl';
import { isIngame } from '@wg/web2clientapi/utils/checkIngame';

export function handleExternalLink(element: HTMLElement) {
  function handleClick(e: MouseEvent) {
    const target = e.target as HTMLElement;

    if (isIngame() && target.tagName === 'A') {
      e.preventDefault();

      const href = target.getAttribute('href');
      openExternalUrl(href);
    }
  }

  element.addEventListener('click', handleClick);

  return {
    destroy() {
      element.removeEventListener('click', handleClick);
    },
  };
}
