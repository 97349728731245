import { shipyardHideScreenshot } from '@/shipyard/shipyardHideScreenshot';
import { shipyardMoveCamera } from '@/shipyard/shipyardMoveCamera';
import { openSseShipyard } from "@/shipyard/openSseShipyard";


export default {
  shipyardHideScreenshot,
  shipyardMoveCamera,
  openSseShipyard,
}
