import web2clientSounds from '@wg/web2clientapi/sound';
import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Toggle.module.scss';

export interface IProps extends ComponentProps {
    caption: string;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

class Toggle extends React.PureComponent<IProps> {
    public onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        web2clientSounds.playButtonSound();
        this.props.onClick(event);
    };

    public render() {
        return (
            <div className={classNames(styles.toggle, this.props?.className)} onClick={this.onClick}>
                {this.props.caption}
            </div>
        );
    }
}

export default Toggle;
