import EpicGamesStore from '@/platform/EpicGamesStore';
import Steam from '@/platform/Steam';
import WinStore from '@/platform/WinStore';


export default {
  EpicGamesStore,
  Steam,
  WinStore,
}
