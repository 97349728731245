import { Settings } from '@/settings';

import App from './App.svelte';

export default function (settings: Settings) {
  new App({
    target: document.body,
    props: {
      settings,
    },
  });
}
