import { Nullable } from '@/interfaces/Nullable';
import { ObjectLiteral } from '@/interfaces/ObjectLiteral';

interface InterpolationParams extends ObjectLiteral<Nullable<string | number>> {
  count?: number;
}

interface Formats {
  DATETIME_FORMAT: string;
  DATETIME_INPUT_FORMATS: Array<string>;
  DATE_FORMAT: string;
  DATE_INPUT_FORMATS: Array<string>;
  DECIMAL_SEPARATOR: string;
  FIRST_DAY_OF_WEEK: number;
  MONTH_DAY_FORMAT: string;
  NUMBER_GROUPING: number;
  SHORT_DATETIME_FORMAT: string;
  SHORT_DATE_FORMAT: string;
  THOUSAND_SEPARATOR: string;
  TIME_FORMAT: string;
  TIME_INPUT_FORMATS: Array<string>;
  YEAR_MONTH_FORMAT: string;
}

type Catalog = Map<string, string>;
type FormatNumber = (value: number, toFixed?: number) => string;
type GetFormat = (format_type: string) => string | Array<string> | number;
type GetText = (messageId: string) => string;
type NGetText = (singular: string, plural: string, count: number) => string;
type Interpolate = (messageId: string, obj: any, named: boolean) => string;

export interface Module {
  catalog: Catalog;
  format_number: FormatNumber;
  formats: Formats;
  get_format: GetFormat;
  gettext: GetText;
  ngettext: NGetText;
  interpolate: Interpolate;
  jsi18n_initialized: boolean;
}

function interpolate(messageId, params, named) {
  return messageId.replace(/{(\w+)}/g, (match, key: string) => {
    return params[key] ? `${params[key]}` : '';
  });
}

function createModulePolyfill(): Partial<Module> {
  return {
    gettext(messageId) {
      return `_${messageId}`;
    },

    ngettext(singular, plural, count) {
      return `_${singular || plural}`;
    },

    interpolate,
  };
}

function getModule(): Nullable<Module> {
  const module = window.i18n;

  if (!module?.jsi18n_initialized) {
    return null;
  }

  return module;
}

export function t(messageId: string, params?: InterpolationParams): string {
  const module = getModule() || createModulePolyfill();

  if (params) {
    if (params.count !== undefined) {
      return interpolate(module.ngettext(messageId, messageId, params.count), params, true);
    }

    return interpolate(module.gettext(messageId), params, true);
  }

  return module.gettext(messageId);
}
