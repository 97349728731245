import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './ClanLeagueSignInline.module.scss';

export interface IProps extends ComponentProps {
    color: string;
    title: string;
}

class ClanLeagueSignInline extends React.PureComponent<IProps, any> {
    public render() {
        return (
            <div
                className={classNames(styles.container, this.props.className)}
                style={{ borderColor: this.props.color }}
            >
                {this.props.title}
            </div>
        );
    }
}

export default ClanLeagueSignInline;
