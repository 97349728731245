import * as React from 'react';

import { ComponentProps } from '@/components/Component';

export interface IProps extends ComponentProps {
    children: React.ReactNode;
}

const Tooltip = (props: IProps) => {
    return <div className={props.className || ''}>{props.children}</div>;
};

export default Tooltip;
