import { PurchaseResultEntity as PurchaseResultEntityInterface } from './interfaces';
import PurchaseResult from './PurchaseResult.svelte';

export type PurchaseResultEntity = PurchaseResultEntityInterface;

export interface PurchaseResultProps {
  purchaseResult: PurchaseResultEntity;
  onClose?: () => void;
}

export default PurchaseResult;

export const mountPurchaseResult = (target: Element | ShadowRoot, props: PurchaseResultProps): PurchaseResult => {
  return new PurchaseResult({ target, props });
};
