import * as React from 'react';

import { ComponentProps } from '@/components/Component';

export interface Props extends ComponentProps {
    color: string;
}

export const R1 = (props: Props) => (
    <svg
        id={'R1'}
        className={props.className || ''}
        width="50"
        height="45"
        viewBox="0 0 50 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M35.1284 36.9042C33.1274 37.6608 28.8768 39.3468 27.8825 40.0371L27.9804 41.776C30.7099 42.7867 36.3709 44.8285 37.179 44.9098C37.9871 44.9912 41.3274 42.3134 42.8965 40.9644L35.1284 36.9042Z"
            fill={props.color}
        />
    </svg>
);

export const R2 = (props: Props) => (
    <svg
        id={'R2'}
        className={props.className || ''}
        width="50"
        height="45"
        viewBox="0 0 50 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M39.8462 28.1102C38.9553 29.9773 37.1201 34.0001 36.9063 35.1543L38.2426 36.3425C40.945 35.2675 46.5099 32.9986 47.1514 32.5234C47.7928 32.0481 48.2501 27.9122 48.3986 25.9036L39.8462 28.1102Z"
            fill={props.color}
        />
    </svg>
);

export const R3 = (props: Props) => (
    <svg
        id={'R3'}
        className={props.className || ''}
        width="50"
        height="45"
        viewBox="0 0 50 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M41.3565 17.2971C41.3904 19.3478 41.5394 23.7317 41.8648 24.862L43.6064 25.3909C45.5656 23.3174 49.5755 18.9976 49.9416 18.3065C50.3077 17.6154 48.8626 13.6911 48.0943 11.8153L41.3565 17.2971Z"
            fill={props.color}
        />
    </svg>
);

export const R4 = (props: Props) => (
    <svg
        id={'R4'}
        className={props.className || ''}
        width="50"
        height="45"
        viewBox="0 0 50 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M36.5514 8.26277C37.8033 9.93131 40.5427 13.4568 41.4851 14.2103L43.2334 13.7019C43.6073 10.9349 44.3273 5.20931 44.2159 4.44249C44.1046 3.67567 40.5734 1.23041 38.8217 0.103638L36.5514 8.26277Z"
            fill={props.color}
        />
    </svg>
);

export const L1 = (props: Props) => (
    <svg
        id={'L1'}
        className={props.className || ''}
        width="50"
        height="45"
        viewBox="0 0 50 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.9354 36.9582C16.945 37.7181 21.2138 39.4112 22.2123 40.1045L22.114 41.8509C19.3728 42.8659 13.6875 44.9165 12.8759 44.9982C12.0643 45.0799 8.70977 42.3906 7.13394 41.0358L14.9354 36.9582Z"
            fill={props.color}
        />
    </svg>
);

export const L2 = (props: Props) => (
    <svg
        id={'L2'}
        className={props.className || ''}
        width="50"
        height="45"
        viewBox="0 0 50 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.1973 28.1267C11.092 30.0018 12.9351 34.0419 13.1498 35.201L11.8078 36.3943C9.09385 35.3147 3.50498 33.0361 2.8608 32.5588C2.21662 32.0815 1.75734 27.9279 1.60823 25.9107L10.1973 28.1267Z"
            fill={props.color}
        />
    </svg>
);

export const L3 = (props: Props) => (
    <svg
        id={'L3'}
        className={props.className || ''}
        width="50"
        height="45"
        viewBox="0 0 50 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.68052 17.2673C8.64654 19.3267 8.49688 23.7295 8.17008 24.8647L6.42103 25.3958C4.45345 23.3134 0.426349 18.9751 0.0586409 18.281C-0.309067 17.587 1.14226 13.6458 1.91389 11.762L8.68052 17.2673Z"
            fill={props.color}
        />
    </svg>
);

export const L4 = (props: Props) => (
    <svg
        id={'L4'}
        className={props.className || ''}
        width="50"
        height="45"
        viewBox="0 0 50 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.5062 8.19413C12.2489 9.86983 9.49777 13.4104 8.55136 14.1671L6.79558 13.6566C6.42008 10.8777 5.69702 5.12757 5.80883 4.35746C5.92065 3.58735 9.46701 1.13161 11.2262 0L13.5062 8.19413Z"
            fill={props.color}
        />
    </svg>
);
