export const DWH_EVENTS = {
  APP_START: 'app.start',

  OPEN_PURCHASE_POPUP_FROM_CATEGORY: 'purchase_popup.open_from_category',
  OPEN_PURCHASE_POPUP_FROM_BUNDLE: 'purchase_popup.open_from_bundle',

  BIND_METHOD: 'payment.bind_method',

  REDIRECT_TO_MORE_PAYMENT_METHODS: 'shop.redirect_to_more_methods',
  REDIRECT_TO_PURCHASE_FROM_CATEGORY: 'shop.redirect_from_category',
  REDIRECT_TO_PURCHASE_FROM_BUNDLE: 'shop.redirect_from_bundle',
  REDIRECT_TO_PURCHASE_FROM_PROMO_EVENT: 'shop.redirect_from_promo_event',

  PURCHASE_REQUEST: 'purchase.request',
  PURCHASE_PAYMENT_REQUEST: 'purchase.payment_request',
  PURCHASE_CANCELED: 'purchase.canceled',
  PURCHASE_ERROR: 'purchase.error',
  PURCHASE_SUCCESS: 'purchase.success',

  FRAUD_PROTECTION_SHOW_COUNTRY_POPUP: 'fraud.show_country_mismatch_popup',

  USER_COUNTRY_NOT_SET_POPUP: 'shop.user_country_not_set_popup',
  CALCULATE_PRICE_FOR_PRODUCT: 'shop.calculate_price_for_product',
};

export type DwhEvent = typeof DWH_EVENTS[keyof typeof DWH_EVENTS];
