import web2clientSounds from '@wg/web2clientapi/sound';
import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Checkbox.module.scss';

export interface IProps extends ComponentProps {
    /** Set checkbox set value */
    isChecked?: boolean;
    /** Disables checkbox */
    isDisabled?: boolean;
    /** onChange checkbox event handler */
    onChange: (val: boolean) => void;
}

export type IState = {
    tooltipId: string;
};

class Checkbox extends React.PureComponent<IProps> {
    public static defaultProps = {
        isChecked: false,
        isDisabled: false,
    };

    public onClick(event: React.MouseEvent<HTMLLabelElement>) {
        event.stopPropagation();
    }

    public onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        web2clientSounds.playCheckboxSound();
        if (this.props.onChange) {
            this.props.onChange(!this.props.isChecked);
        }
    };

    public render() {
        return (
            <label className={classNames(styles.label, this.props.className)} onClick={this.onClick}>
                <input
                    className={styles.input}
                    type="checkbox"
                    checked={this.props.isChecked}
                    disabled={this.props.isDisabled}
                    onChange={this.onChange}
                />
                <span className={styles.checkbox} />
            </label>
        );
    }
}

export default Checkbox;
