import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './ButtonScroll.module.scss';

export interface ButtonScrollProps extends ComponentProps {
    onClick: () => void;
    isHideIconScroll: boolean;
    size?: 'large' | 'medium' | 'small';
    direction?: 'down' | 'up';
}

function ButtonScroll({ isHideIconScroll, onClick, size, direction = 'down', className = '' }: ButtonScrollProps) {
    let iconMouse = '';
    let iconArrow = '';
    switch (size) {
        case 'large':
            iconMouse = classNames(styles.iconMouse, styles.large);
            iconArrow = classNames(styles.iconArrow, styles.large);
            break;
        case 'medium':
            iconMouse = classNames(styles.iconMouse, styles.medium);
            iconArrow = classNames(styles.iconArrow, styles.medium);
            break;
        case 'small':
            iconMouse = classNames(styles.iconMouse, styles.small, styles.correctPosition);
            iconArrow = classNames(styles.iconArrow, styles.small);
            break;
        default:
            iconMouse = classNames(styles.iconMouse);
            iconArrow = classNames(styles.iconArrow);
    }
    return (
        <div
            className={classNames(
                styles.icon,
                { [styles.isHide]: isHideIconScroll },
                { [className]: className !== '' },
            )}
            onClick={onClick}
        >
            {direction === 'up' && <div className={classNames(iconArrow, styles.directionUp)} />}
            <div className={iconMouse} />
            {direction === 'down' && <div className={iconArrow} />}
        </div>
    );
}

export default React.memo(ButtonScroll);
