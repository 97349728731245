import { getUserCountry } from '@/platform/WinStore/getUserCountry';
import { getWinStoreAccountMatchInfo } from '@/platform/WinStore/getWinStoreAccountMatchInfo';
import { getWinStoreItems } from '@/platform/WinStore/getWinStoreItems';
import { initWinStoreTransaction } from '@/platform/WinStore/initWinStoreTransaction';


export default {
  getUserCountry,
  getWinStoreAccountMatchInfo,
  getWinStoreItems,
  initWinStoreTransaction,
};
