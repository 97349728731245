import { getFriendsList } from '@/platform/Steam/getFriendsList';
import { getUserRestrictions } from '@/platform/Steam/getUserRestrictions';
import { replyToFriendMessageByIndex } from '@/platform/Steam/replyToFriendMessageByIndex';
import { replyToFriendMessageBySteamId } from '@/platform/Steam/replyToFriendMessageBySteamId';


export default {
  getFriendsList,
  getUserRestrictions,
  replyToFriendMessageByIndex,
  replyToFriendMessageBySteamId,
};
