import { CategoryEntity } from '@/core/entity/category.entity';
import { ProductEntity } from '@/core/entity/product.entity';
import { UserEntity } from '@/core/entity/user.entity';
import { VariablePriceEntity } from '@/core/entity/varuablePrice.entity';
import { getCategories } from '@/core/platform/EpicGamesStore/categories';
import { checkout } from '@/core/platform/EpicGamesStore/checkout';
import { getProducts } from '@/core/platform/EpicGamesStore/products';
import { getUser, isAccountCompleted } from '@/core/platform/EpicGamesStore/user';
import { Nullable } from '@/interfaces/Nullable';
import { PlatformProvider } from '@/interfaces/PlatformProvider';
import { CheckoutResult } from '@/interfaces/Product';

export class EpicGamesStorePlatform implements PlatformProvider {
  products(customStorefrontName?: string): Promise<ProductEntity[]> {
    return getProducts(customStorefrontName);
  }

  categories(customStorefrontName?: string): Promise<CategoryEntity[]> {
    return getCategories(customStorefrontName);
  }

  checkout(externalProductId: string, quantity?: number, customStorefrontName?: string): Promise<CheckoutResult> {
    return checkout(externalProductId, customStorefrontName);
  }

  user(force: boolean = false): Promise<UserEntity> {
    return getUser(force);
  }

  isAccountCompleted(): Promise<boolean> {
    return isAccountCompleted();
  }

  getProductVariablePrice(
    productCode: string,
    quantity: number,
    customStorefrontName?: string,
  ): Promise<Nullable<VariablePriceEntity>> {
    return Promise.resolve(undefined);
  }
}
