import { sendAction } from '@/core/action';
import { log } from '@/utils/logger';
import WinStoreCallbackObserver, { WinStoreCallbacks } from '@/platform/WinStore/WinStoreCallbackObserver';


export type WinStoreAccountMatchInfo = 'matched' | 'unmatched';

export function getWinStoreAccountMatchInfo(): Promise<WinStoreAccountMatchInfo> {
  return new Promise((resolve) => {
    WinStoreCallbackObserver.once(WinStoreCallbacks.onAccountDataAvailable, (accountMatchStatus) => {
      log('Resolve getWinStoreAccountMatchInfo method', accountMatchStatus);

      resolve(accountMatchStatus);
    });

    sendAction({
      actionId: 'getAccountMatchInfo',
    });
  });
}
