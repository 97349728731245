import { AsyncResponse, sendAsyncAction } from '@/core/async';


export interface OfferItem {
  itemId: string,
  title: string,
}

export interface EpicStoreOffer {
  offerId: string,
  title: string,
  currencyCode: string,
  currentPrice: string,
  originalPrice: string | null,
  discountPercentage:  string,
  expirationTimestamp: string,
  bAvailableForPurchase: string,
  purchasedCount: string,
  purchaseLimit: string,
  items: OfferItem[],
}

export interface QueryEpicStoreOffers {
  offers: EpicStoreOffer[],
}

export function queryEpicStoreOffers(): Promise<AsyncResponse<QueryEpicStoreOffers>> {
  return sendAsyncAction({
    actionId: 'queryEpicStoreOffers',
  });
}
