import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Icon.module.scss';

export type IconType =
    | 'clan-info'
    | 'info'
    | 'status-accepted'
    | 'status-active'
    | 'status-declined'
    | 'status-expired'
    | 'go-to'
    | 'discount'
    | 'badge-new'
    | 'leading-team';
export interface IProps extends ComponentProps {
    glyph: IconType;
}

const Icon = React.memo((props: IProps) => <i className={classNames(styles[props.glyph], props.className)} />);

export default Icon;
