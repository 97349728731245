import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import TooltipWrapper from './TooltipWrapper';

interface IPropsProvider extends ComponentProps {
    children: any;
}

class TooltipProvider extends React.PureComponent<IPropsProvider> {
    public prevContent: HTMLDivElement | null = null;

    public TooltipWrapper: any;

    public componentDidMount() {
        if (typeof window !== undefined && !window.tooltipProvider) {
            // @ts-ignore
            window.tooltipProvider = this;
        }
        document?.addEventListener('mousewheel', this.onmousewheel);
    }

    public componentWillUnmount() {
        if (typeof window !== undefined && window.tooltipProvider) {
            delete window.tooltipProvider;
        }
        document?.removeEventListener('mousewheel', this.onmousewheel);
    }

    public onmousewheel() {
        if (window?.tooltipProvider) {
            window.tooltipProvider.hide();
        }
    }

    public isVisible = () => {
        if (this.TooltipWrapper) {
            return this.TooltipWrapper.state.isTooltipVisible;
        } else {
            return false;
        }
    };

    public setBodyContentIfNeeded = (content: HTMLDivElement | null) => {
        if (!this.TooltipWrapper.state.body) {
            this.TooltipWrapper.setState({ body: content });
        }
    };

    public show = (content: HTMLDivElement | null, delay: number, usePrev: boolean) => {
        if (this.TooltipWrapper) {
            if (usePrev && this.prevContent) {
                this.TooltipWrapper.setState({ isTooltipVisible: true, body: this.prevContent });
            } else {
                this.prevContent = content;
                this.TooltipWrapper.setState({ isTooltipVisible: true, body: content });
            }
        }
    };

    public hide = (removeContent: boolean) => {
        if (this.TooltipWrapper) {
            if (removeContent) {
                this.prevContent = null;
            }
            this.TooltipWrapper.setState({ isTooltipVisible: false, body: null });
        }
    };

    public update = (position: { x?: number; y?: number }) => {
        if (this.TooltipWrapper) {
            this.TooltipWrapper.setState({ position: position, isTooltipVisible: true });
        }
    };

    public render() {
        const keyChildren = {
            ...this.props.children,
            key: 'TooltipProviderChildren',
        };

        return [
            keyChildren,
            <TooltipWrapper
                className={this.props?.className || ''}
                ref={(r: any) => {
                    this.TooltipWrapper = r;
                }}
                key={'TooltipWrapper'}
            />,
        ];
    }
}

export default TooltipProvider;
