import { openExternalUrl } from '@wg/web2clientapi/browser/openExternalUrl';
import { isIngame } from '@wg/web2clientapi/utils/checkIngame';

export function openUrl(url: string): void {
  if (isIngame()) {
    openExternalUrl(url);

    return;
  }

  window.open(url);
}
