import { closeBrowser } from '@/browser/closeBrowser';
import { closeMetashop } from '@/browser/closeMetashop';
import { openExternalUrl } from '@/browser/openExternalUrl';
import { updateBrowserControlState } from '@/browser/updateBrowserControlState';
import { updateBrowserOverScrollableState } from '@/browser/updateBrowserOverScrollableState';
import { completeAccount } from '@/browser/completeAccount'
import { coachCompleteAccount } from '@/browser/coachCompleteAccount'
import { goToWebProject } from '@/browser/goToWebProject';
import { earlyWebContentLoading } from '@/browser/earlyWebContentLoading';
import { openBattlePass } from '@/browser/openBattlePass';
import { openRvR } from '@/browser/openRvR';
import { navigateTo } from '@/browser/navigateTo';
import { isLowPcPerformance } from '@/browser/isLowPcPerformance';


export default {
  closeBrowser,
  closeMetashop,
  openExternalUrl,
  updateBrowserControlState,
  updateBrowserOverScrollableState,
  goToWebProject,
  completeAccount,
  coachCompleteAccount,
  earlyWebContentLoading,
  openBattlePass,
  openRvR,
  navigateTo,
  isLowPcPerformance,
}
