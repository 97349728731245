import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './IconTable.module.scss';

export type IconTableTypes =
    | 'battles-per-day'
    | 'battles'
    | 'clan'
    | 'clan-level'
    | 'clan-resource'
    | 'damage-per-battle'
    | 'days-in-clan'
    | 'experience-per-battle'
    | 'frags-per-battle'
    | 'members-count'
    | 'last-battle-time'
    | 'location'
    | 'rating'
    | 'roles'
    | 'wins-per-bettles'
    | 'search';
export interface IProps extends ComponentProps {
    glyph: IconTableTypes;
    onClick?: () => void;
}

class IconTable extends React.PureComponent<IProps, any> {
    public onClick = (event: React.MouseEvent<HTMLElement>) => {
        if (this.props.onClick) {
            this.props.onClick();
            event.stopPropagation();
        }
    };

    public render() {
        return <i className={classNames(styles[this.props.glyph], this.props.className)} onClick={this.onClick} />;
    }
}

export default IconTable;
