import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './ProgressBar.module.scss';

export interface IProgressBar extends ComponentProps {
    completed: number;
    size: 'small' | 'large';
    isNavProgress?: boolean;
    color?: string;
}

export interface IProgressBarStyles {
    [key: string]: string;
}

const ProgressBar = React.memo((props: IProgressBar): any => {
    const progressBarStyle: IProgressBarStyles = {
        width: props.completed * 100 + '%',
    };

    if (props.color) {
        progressBarStyle.background = props.color;
    }

    const classNameBase = classNames(styles.base, props.className, {
        [styles.isNavProgress]: props.isNavProgress,
    });

    return (
        <div className={classNameBase}>
            <div style={progressBarStyle} className={styles[props.size]} />
        </div>
    );
});

export default ProgressBar;
