import classNames from 'classnames';
import * as React from 'react';
import Highlight from 'react-highlighter';

import { ComponentProps } from '@/components/Component';

import styles from './ClanTag.module.scss';

export interface IProps extends ComponentProps {
    clanTag: string;
    clanColor: string;
    highlight?: string;
}

class ClanTag extends React.PureComponent<IProps, any> {
    public render() {
        const clanTag = this.props.highlight ? (
            <Highlight search={this.props.highlight} matchElement="span">
                {this.props.clanTag}
            </Highlight>
        ) : (
            this.props.clanTag
        );

        return (
            <span className={classNames(styles.tag, this.props.className)} style={{ color: this.props.clanColor }}>
                [{clanTag}]
            </span>
        );
    }
}

export default ClanTag;
