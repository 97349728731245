import { PurchaseStatus } from '@/constants/purchaseStatuses';

export const BRIDGE_EVENT_TYPES = {
  APPLICATION_LOADED: 'APPLICATION_LOADED',
  CLOSE_APPLICATION: 'CLOSE_APPLICATION',
  PURCHASE_STATUS: 'PURCHASE_STATUS',
  ESCAPE_BUTTON: 'ESCAPE_BUTTON',
};

export interface ApplicationLoadedEvent {
  type: 'APPLICATION_LOADED';
}

export interface CloseApplicationEvent {
  type: 'CLOSE_APPLICATION';
}

export interface PurchaseStatusEvent {
  type: 'PURCHASE_STATUS';
  productCode: string;
  purchaseStatus: PurchaseStatus;
  errorCode?: string;
}

export interface EscapeButtonEvent {
  type: 'ESCAPE_BUTTON';
  handled: boolean;
}

export type BridgeEvent = ApplicationLoadedEvent | CloseApplicationEvent | PurchaseStatusEvent | EscapeButtonEvent;
