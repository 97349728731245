import { PLATFORMS } from '@/constants/platforms';
import settings from '@/settings';
import getCookie from '@/utils/getCookie';

export default function (): string {
  const platformCookie = getCookie('wows_client_platform');

  if (settings.showDevPanel && settings.platform) {
    return settings.platform;
  }

  return platformCookie && Object.values(PLATFORMS).includes(platformCookie) ? platformCookie : PLATFORMS.WEB;
}
