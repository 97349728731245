import { Events } from '@/constants/events';
import { ObjectLiteral } from '@/interfaces/ObjectLiteral';

export type EventEmitterCallback = (payload: any) => void;

export interface EventEmitter {
  emit(eventName: Events, payload?: any): void;
  subscribe(eventName: Events, callback: EventEmitterCallback): void;
}

class Emitter implements EventEmitter {
  private listeners: ObjectLiteral<EventEmitterCallback[]> = {};

  emit(eventName: Events, payload?: any): void {
    if (this.listeners[eventName]) {
      this.listeners[eventName].forEach((callback: EventEmitterCallback) => {
        callback(payload);
      });
    }
  }

  subscribe(eventName: Events, callback: EventEmitterCallback): void {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }

    this.listeners[eventName].push(callback);
  }
}

export default new Emitter();
