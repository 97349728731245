import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import Sticky from '../Sticky/Sticky';
import styles from './Table.module.scss';

interface IProps extends ComponentProps {
    children?: React.ReactChild;
    stickyContainerId?: string;
    stickyAppContainerId?: string;
    stickyScrollContainerId?: string;
    isActiveSticky?: boolean;
    style?: React.CSSProperties;
}

class TableHead extends React.PureComponent<IProps, any> {
    public render() {
        const className = classNames(styles.thead, this.props.className);
        return this.props.stickyContainerId && this.props.stickyAppContainerId && this.props.stickyScrollContainerId ? (
            <Sticky
                appContainerId={this.props.stickyAppContainerId}
                scrollContainerId={this.props.stickyScrollContainerId}
                usedStickyContainerId={this.props.stickyContainerId}
                isActive={this.props.isActiveSticky}
            >
                <div className={className}>{this.props.children}</div>
            </Sticky>
        ) : (
            <div style={this.props.style ? this.props.style : {}} className={className}>
                {this.props.children}
            </div>
        );
    }
}

export default TableHead;
