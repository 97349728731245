import classNames from 'classnames';
import React, { PureComponent } from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Shadows.module.scss';

export interface IProps extends ComponentProps {
    children?: React.ReactNode;
    appContainerId?: string;
}

export interface IState {
    showShadowTop: boolean;
    showShadowBottom: boolean;
    shadowPositionLeft: number;
    shadowWidth: number;
    prevScrollPosition: number | null;
}

class Shadows extends PureComponent<IProps, IState> {
    private appContainer: HTMLElement | null;

    private _section: HTMLElement | null;

    public state: IState = {
        showShadowTop: false,
        showShadowBottom: false,
        shadowPositionLeft: 0,
        shadowWidth: 0,
        prevScrollPosition: null,
    };

    public componentDidMount() {
        const id = this.props.appContainerId ? this.props.appContainerId : 'app';
        this.appContainer = document.getElementById(id);
        if (this.appContainer) {
            this.appContainer.addEventListener('scroll', this.updateShadows);
        }
        window?.addEventListener('resize', this.updateShadows);
        this.updateShadows();
    }

    public componentDidUpdate() {
        this.updateShadows();
    }

    public componentWillUnmount() {
        if (this.appContainer) {
            this.appContainer.removeEventListener('scroll', this.updateShadows);
        }
        window?.removeEventListener('resize', this.updateShadows);
    }

    public updateShadows = () => {
        const scrollPosition = this.appContainer ? this.appContainer.scrollTop : 0;
        const scrollHeight = this.appContainer ? this.appContainer.scrollHeight - this.appContainer.clientHeight : 0;

        const state: IState = {
            showShadowTop: scrollPosition > 0,
            showShadowBottom: scrollPosition < scrollHeight,
            shadowPositionLeft: this._section ? this._section.getBoundingClientRect().left : 0,
            shadowWidth: this._section ? this._section.getBoundingClientRect().width : 0,
            prevScrollPosition: null,
        };

        if (scrollPosition > 0) {
            state.prevScrollPosition = scrollPosition;
        }

        this.setState(state);
    };

    public render() {
        const bottomStyle = {
            left: this.state.shadowPositionLeft,
            width: this.state.shadowWidth,
        };

        const topStyle = {
            left: this.state.shadowPositionLeft,
            width: this.state.shadowWidth,
        };

        return (
            <section
                className={classNames(styles.section, this.props?.className)}
                ref={(c) => {
                    if (c) {
                        this._section = c;
                    }
                }}
            >
                <div
                    className={`${styles.shadowTop} ${this.state.showShadowTop ? styles.isVisible : ''}`}
                    style={topStyle}
                />
                {this.props.children}
                <div
                    className={`${styles.shadowBottom} ${this.state.showShadowBottom ? styles.isVisible : ''}`}
                    style={bottomStyle}
                />
            </section>
        );
    }
}

export default Shadows;
