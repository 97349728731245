import { safeJSONParse } from '@/utils/safeJSONParse';


export type ClientStateActionId = 'startShipPreview';

export interface ClientStatePayload {
  actionId: ClientStateActionId;
}

export type ClientStateCallback = (payload: ClientStatePayload) => void;

let clientStateCallbacks: ClientStateCallback[] = [];

window.onClientStateChange = (text: string) => {
  const payload = <ClientStatePayload>safeJSONParse(text);

  clientStateCallbacks.forEach((callback) => {
    callback(payload);
  });
}

export function onClientStateChange(callback: ClientStateCallback) {
  clientStateCallbacks.push(callback);
}
