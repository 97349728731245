import * as React from 'react';
import classNames from 'classnames';

import { ComponentProps } from '@/components/Component';

import StepCSSTransitionGroup from '../Animations/StepCSSTransitionGroup';
import styles from './Dialog.module.scss';

export interface ICustomizeProps extends ComponentProps {
    isOverlayContent?: boolean;
}

export interface IProps extends ICustomizeProps {
    children: React.ReactNode;
}

class DialogFooter extends React.PureComponent<IProps> {
    constructor(props: IProps) {
        super(props);
    }
    
    public render() {
        const classNameDialogFooter = classNames(styles.footer, {
            [styles.isOverlayContent]: !!this.props.isOverlayContent
        });
        
        return (
            <StepCSSTransitionGroup level={3} className={this.props.className || ''}>
                <div key="footer" className={classNameDialogFooter}>
                    {this.props.children}
                </div>
            </StepCSSTransitionGroup>
        );
    }
}

export default DialogFooter;
