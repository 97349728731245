import { SvelteComponent, init, safe_not_equal, create_component, mount_component, transition_in, transition_out, destroy_component, text, insert, noop, detach, element, space, attr, append, listen, empty } from '../../../external_node_modules/svelte/internal/index.mjs.js';
import { t } from '../../../i18n.js';
import { isIngame } from '@wg/web2clientapi/utils/checkIngame';
import { openExternalUrl } from '@wg/web2clientapi/browser/openExternalUrl';
import { disableBodyOverflow } from '../../../utils/disableBodyOverflow.js';
import PurchaseRestrictionPopup from '../../shared/components/PurchaseRestrictionPopup/PurchaseRestrictionPopup.svelte.js';
import '../../shared/components/PurchaseRestrictionPopup/assets/styles/purchaseRestriction.scss.js';

/* src/ui/PurchaseRestrictions/PurchaseRestrictionByUserCountryNotSet/App.svelte generated by Svelte v3.44.2 */

function create_purchase_restriction_title_slot(ctx) {
	let t_1_value = t('место жительства') + "";
	let t_1;

	return {
		c() {
			t_1 = text(t_1_value);
		},
		m(target, anchor) {
			insert(target, t_1, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t_1);
		}
	};
}

// (40:4) {#if userSetCountryUrl}
function create_if_block(ctx) {
	let div1;
	let button;
	let t0_value = t('Продолжить') + "";
	let t0;
	let t1;
	let div0;
	let mounted;
	let dispose;

	return {
		c() {
			div1 = element("div");
			button = element("button");
			t0 = text(t0_value);
			t1 = space();
			div0 = element("div");
			attr(div0, "class", "purchase-restriction-button__arrow");
			attr(button, "class", "purchase-restriction-button purchase-restriction-button--outline");
			attr(div1, "class", "purchase-restriction__action");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, button);
			append(button, t0);
			append(button, t1);
			append(button, div0);

			if (!mounted) {
				dispose = listen(button, "click", /*openPremiumShop*/ ctx[4]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div1);
			mounted = false;
			dispose();
		}
	};
}

// (36:2) <svelte:fragment slot="purchase-restriction-content">
function create_purchase_restriction_content_slot(ctx) {
	let div;
	let t1;
	let if_block_anchor;
	let if_block = /*userSetCountryUrl*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			div.textContent = `${t('Для продолжения необходимо указать страну вашего проживания')}`;
			t1 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(div, "class", "purchase-restriction__text");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			insert(target, t1, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (/*userSetCountryUrl*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching) detach(t1);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let purchaserestrictionpopup;
	let current;

	purchaserestrictionpopup = new PurchaseRestrictionPopup({
			props: {
				purchaseRestrictionId: /*userCountryNotSetId*/ ctx[0],
				isClosable: /*isClosable*/ ctx[2],
				closeApplication: /*closeApplication*/ ctx[1],
				purchaseRestrictionAction: /*openPremiumShop*/ ctx[4],
				$$slots: {
					"purchase-restriction-content": [create_purchase_restriction_content_slot],
					"purchase-restriction-title": [create_purchase_restriction_title_slot]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(purchaserestrictionpopup.$$.fragment);
		},
		m(target, anchor) {
			mount_component(purchaserestrictionpopup, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const purchaserestrictionpopup_changes = {};
			if (dirty & /*userCountryNotSetId*/ 1) purchaserestrictionpopup_changes.purchaseRestrictionId = /*userCountryNotSetId*/ ctx[0];
			if (dirty & /*isClosable*/ 4) purchaserestrictionpopup_changes.isClosable = /*isClosable*/ ctx[2];
			if (dirty & /*closeApplication*/ 2) purchaserestrictionpopup_changes.closeApplication = /*closeApplication*/ ctx[1];

			if (dirty & /*$$scope, userSetCountryUrl*/ 72) {
				purchaserestrictionpopup_changes.$$scope = { dirty, ctx };
			}

			purchaserestrictionpopup.$set(purchaserestrictionpopup_changes);
		},
		i(local) {
			if (current) return;
			transition_in(purchaserestrictionpopup.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(purchaserestrictionpopup.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(purchaserestrictionpopup, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { userCountryNotSetId } = $$props;
	let { language } = $$props;
	let { closeApplication } = $$props;
	let { isClosable } = $$props;
	let { userSetCountryUrl } = $$props;
	disableBodyOverflow(true);

	function openPremiumShop() {
		if (isIngame()) {
			return openExternalUrl(userSetCountryUrl);
		}

		window.open(userSetCountryUrl);
	}

	$$self.$$set = $$props => {
		if ('userCountryNotSetId' in $$props) $$invalidate(0, userCountryNotSetId = $$props.userCountryNotSetId);
		if ('language' in $$props) $$invalidate(5, language = $$props.language);
		if ('closeApplication' in $$props) $$invalidate(1, closeApplication = $$props.closeApplication);
		if ('isClosable' in $$props) $$invalidate(2, isClosable = $$props.isClosable);
		if ('userSetCountryUrl' in $$props) $$invalidate(3, userSetCountryUrl = $$props.userSetCountryUrl);
	};

	return [
		userCountryNotSetId,
		closeApplication,
		isClosable,
		userSetCountryUrl,
		openPremiumShop,
		language
	];
}

class App extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			userCountryNotSetId: 0,
			language: 5,
			closeApplication: 1,
			isClosable: 2,
			userSetCountryUrl: 3
		});
	}
}

export { App as default };
