import web2clientSounds from '@wg/web2clientapi/sound';
import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';
import { t } from '~/helpers/localization';

import Label from '../Label/Label';
import Spinner from '../Spinner/Spinner';
import styles from './Textarea.module.scss';

export interface IProps extends ComponentProps {
    label?: string;
    value: string;
    width?: string;
    height?: string;
    maxLength?: number;
    error?: string;
    isDisabled?: boolean;
    isValidating?: boolean;
    isCounted?: boolean;
    isFocusOn?: boolean;
    onChange: (text: string) => void;
}

class Textarea extends React.PureComponent<IProps> {
    public _textarea: HTMLTextAreaElement;

    constructor(props: IProps) {
        super(props);
    }

    public componentDidMount() {
        if (this.props.isFocusOn) {
            this._textarea.focus();
        }
    }

    public onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let description = event.target.value;
        if (this.props.maxLength && description.length > this.props.maxLength) {
            description = description.slice(0, this.props.maxLength);
        }
        this.props.onChange(description);
        web2clientSounds.playInputSound();
    };

    public render() {
        const classNameTextarea = classNames(
            styles.textarea,
            {
                [styles.isErrored]: this.props.error,
            },
            this.props?.className,
        );

        const label = this.props.label ? <Label>{this.props.label}</Label> : null;

        const validator = this.props.isValidating ? (
            <div className={styles.validator}>
                <Spinner />
                &nbsp;{t('Проверка:валидация')}
            </div>
        ) : null;

        const error = this.props.error ? <div className={styles.error}>{this.props.error}</div> : null;

        const counter = this.props.isCounted ? (
            <div className={styles.counter}>
                <span className={styles.counterCurrent}>{this.props.value.length}</span> / {this.props.maxLength}
            </div>
        ) : null;

        return (
            <div>
                {label}
                <div className={styles.container}>
                    <textarea
                        className={classNameTextarea}
                        style={{ width: this.props.width, height: this.props.height }}
                        value={this.props.value}
                        disabled={this.props.isDisabled}
                        onChange={this.onChange}
                        ref={(ref) => {
                            if (ref !== null) {
                                this._textarea = ref;
                            }
                        }}
                        spellCheck={false}
                    />
                    {validator}
                    {error}
                    {counter}
                </div>
            </div>
        );
    }
}

export default Textarea;
