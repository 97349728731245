import classNames from 'classnames';
import React from 'react';

import { ComponentProps, DeepPureComponent } from '@/components/Component';

import styles from './ButtonSwitch.module.scss';

export interface IProps extends ComponentProps {
    value: boolean;
    onChange: (value: boolean) => void;
    stateTextOn: [string, string];
    stateTextOff: [string, string];
}

export type IState = {};

class ButtonSwitch extends DeepPureComponent<IProps, IState> {
    public getOnText = () => {
        if (this.props.value) {
            return this.props.stateTextOn ? this.props.stateTextOn[1] : 'on';
        } else {
            return this.props.stateTextOn ? this.props.stateTextOn[0] : 'on';
        }
    };

    public getOffText = () => {
        if (this.props.value) {
            return this.props.stateTextOff ? this.props.stateTextOff[0] : 'off';
        } else {
            return this.props.stateTextOff ? this.props.stateTextOff[1] : 'off';
        }
    };

    public turnOn = () => {
        if (!this.props.value && this.props.onChange) {
            this.props.onChange(true);
        }
    };

    public turnOff = () => {
        if (this.props.value && this.props.onChange) {
            this.props.onChange(false);
        }
    };

    public render() {
        return (
            <div className={classNames(styles.wrapper, this.props.className)}>
                <div className={styles.inner}>
                    <div className={`${styles.activeBgOn} ${!this.props.value ? styles.activeBgOff : ''}`} />
                    <div
                        className={`${styles.button} ${styles.buttonOn} ${
                            this.props.value ? styles.buttonOnActive : ''
                        } `}
                        onClick={this.turnOn}
                    >
                        {this.getOnText()}
                    </div>
                    <div
                        className={`${styles.button} ${styles.buttonOff} ${
                            !this.props.value ? styles.buttonOffActive : ''
                        }`}
                        onClick={this.turnOff}
                    >
                        {this.getOffText()}
                    </div>
                </div>
            </div>
        );
    }
}

export default ButtonSwitch;
