import { Price, ProductPrice, ProductPriceDiscount, ProductReward } from '@/interfaces/ProductPrice';

export class VariablePriceEntity implements ProductPrice {
  productCode: string;

  clientPaymentMethodIds: number[];
  discounts: ProductPriceDiscount[];
  price: Price;
  rewards: ProductReward[];
}
