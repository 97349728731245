export const THREE_DS_STATUSES = {
  AUTHORISED: 'authorised',
  CANCELLED: 'cancelled',
  UNEXPECTED: 'unexpected',
  IN_PROGRESS: '3ds_in_progress',
};

export type ThreeDSStatus = typeof THREE_DS_STATUSES[keyof typeof THREE_DS_STATUSES];

export const PENDING_STATUSES = [THREE_DS_STATUSES.IN_PROGRESS];

export const FINISH_STATUSES = [
  THREE_DS_STATUSES.AUTHORISED,
  THREE_DS_STATUSES.CANCELLED,
  THREE_DS_STATUSES.UNEXPECTED,
];
