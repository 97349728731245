import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './RankWrapper.module.scss';

export type RankTypes = 0 | 1 | 2 | 3 | 4;

export interface IRankWrapper extends ComponentProps {
    children: React.ReactNode;
    level: RankTypes;
}

const RankWrapper = React.memo((props: IRankWrapper) => {
    return <div className={classNames(styles[`level-${props.level}`], props.className)}>{props.children}</div>;
});

export default RankWrapper;
