const storage = {};

export function setStorageItem(key: string, data: any): void {
  try {
    window.localStorage.setItem(key, data);
  } catch (err) {
    storage[key] = data;
  }
}

export function getStorageItem(key: string): string | undefined {
  try {
    const text = window.localStorage.getItem(key);

    return JSON.parse(text);
  } catch (err) {
    return storage[key];
  }
}
