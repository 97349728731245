import { sendAction } from '@/core/action';
import { log } from '@/utils/logger';
import WinStoreCallbackObserver, { WinStoreCallbacks } from '@/platform/WinStore/WinStoreCallbackObserver';


export interface WinStoreItem {
  description: string;
  productId: string;
  price: string;
  title: string;
  currencyCode: string;
  language: string;
  inAppOfferToken: string;
  inProgress: boolean;
  salePrice?: string;
  saleEndDate?: string; // 2017-10-31 17:31:59
}

export function getWinStoreItems(): Promise<WinStoreItem[]> {
  return new Promise((resolve) => {
    WinStoreCallbackObserver.once(WinStoreCallbacks.OnAllProductsReceived, (products) => {
      log('Resolve OnAllProductsReceived method', products);

      resolve(products);
    });

    sendAction({
      actionId: 'getWinStoreItems',
    });
  });
}
