import { log, error } from '@/utils/logger';
import { WinStoreItem } from '@/platform/WinStore/getWinStoreItems';


export enum WinStoreCallbacks {
  OnProductReceived = 'OnProductReceived',
  OnAllProductsReceived = 'OnAllProductsReceived',
  OnReceiveFailed = 'OnReceiveFailed',
  onAccountDataAvailable = 'onAccountDataAvailable',
  OnPurchaseSucceed = 'OnPurchaseSucceed',
  OnPurchaseFailed = 'OnPurchaseFailed',
  OnUserCountryReceived = 'OnUserCountryReceived',
}

interface Callbacks {
  [key: string]: ((...args: any[]) => void)[];
}

class WinStoreCallbackObserver {
  private callbacks: Callbacks = {};
  private products: WinStoreItem[] = [];

  constructor() {
    this.init();
  }

  private init(): void {
    window.winStore = new Proxy({}, {
      get: (target, property: WinStoreCallbacks) => {
        return (...args: any[]) => {
          this?.[property]?.apply?.(this, args);
        };
      }
    });
  }

  private emit(name: WinStoreCallbacks, ...payload: any[]) {
    if (this.callbacks[name]) {
      this.callbacks[name].forEach((callback: any) => {
        callback(...payload);
      });

      delete this.callbacks[name];
    }
  }

  private OnProductReceived(product: WinStoreItem) {
    if (!product) {
      error('OnProductReceived', 'Empty product payload');

      throw new Error('Empty product payload');
    }

    log('OnProductReceived', product);
    this.products.push(product);

    this.emit(WinStoreCallbacks.OnProductReceived);
  }

  private OnAllProductsReceived() {
    log('OnAllProductsReceived', this.products);

    this.emit(WinStoreCallbacks.OnAllProductsReceived, this.products);
  }

  private OnReceiveFailed(error: string, code: string) {
    log('OnReceiveFailed', error, code);

    this.emit(WinStoreCallbacks.OnReceiveFailed, error, code);
  }

  private onAccountDataAvailable(accountMatchStatus: string) {
    log('onAccountDataAvailable', accountMatchStatus);

    this.emit(WinStoreCallbacks.onAccountDataAvailable, accountMatchStatus);
  }

  private OnPurchaseSucceed(transactionId: string, inAppOfferToken: string) {
    log('OnPurchaseSucceed', transactionId, inAppOfferToken);

    this.emit(WinStoreCallbacks.OnPurchaseSucceed, transactionId, inAppOfferToken);
  }

  private OnPurchaseFailed(error: string, code: string) {
    log('OnPurchaseFailed', error, code);

    this.emit(WinStoreCallbacks.OnPurchaseFailed, error, code);
  }

  private OnUserCountryReceived(country: string) {
    log('OnUserCountryReceived', country);

    this.emit(WinStoreCallbacks.OnUserCountryReceived, country);
  }

  once(name: WinStoreCallbacks, callback: (...args: any[]) => void) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);
  }
}

export default new WinStoreCallbackObserver();

