import { sendAsyncAction, AsyncResponse } from '@/core/async';


export function replyToFriendMessageBySteamId(steamId: string, msg: string): Promise<AsyncResponse<void>> {
  return sendAsyncAction({
    actionId: 'replyToSteamFriendByID',
    steamId,
    msg,
  });
}
