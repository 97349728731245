import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Count.module.scss';

export interface Props extends ComponentProps {
    value: number;
    min?: number;
    max?: number;
    onChange: (value: number) => void;
}

export default class Count extends React.PureComponent<Props> {
    increase = () => {
        let val = this.props.value + 1;
        if (this.props.max && val > this.props.max) {
            val = this.props.max;
        }
        this.props.onChange(val);
    };

    decrease = () => {
        let val = this.props.value - 1;
        if (this.props.min) {
            if (val < this.props.min) {
                val = this.props.min;
            }
        } else {
            if (val < 0) {
                val = 0;
            }
        }

        this.props.onChange(val);
    };

    render() {
        const increaseDisable = this.props.max && this.props.max === this.props.value;
        const decreaseDisable = this.props.min && this.props.min === this.props.value;
        return (
            <div className={classNames(styles.wrapper, this.props.className)}>
                <div
                    className={`${styles.arrowLeft} ${decreaseDisable ? styles.disabled : ''}`}
                    onClick={this.decrease}
                />
                <div className={styles.value}>{this.props.value}</div>
                <div
                    className={`${styles.arrowRight} ${increaseDisable ? styles.disabled : ''}`}
                    onClick={this.increase}
                />
            </div>
        );
    }
}
