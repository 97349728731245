import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';
import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import styles from './Currency.module.scss';

export type CurrencyType =
    | 'oil'
    | 'coal'
    | 'steel'
    | 'gold-star'
    | 'cwars-star'
    | 'gold'
    | 'credits'
    | 'silver-star'
    | 'exp'
    | 'free-exp'
    | 'ribbon'
    | 'damage'
    | 'eventum'
    | 'clan-tag'
    | 'clan-rank'
    | 'elite-xp';

export interface IProps extends ComponentProps {
    amount: number | string;
    isBold?: boolean;
    type: CurrencyType;
    onlyIcon?: boolean;
    withMargin?: boolean;
    color?: string;
    isFlat?: boolean;
}

export interface ITypes {
    [key: string]: string;
}

export interface INames {
    [key: string]: string;
}

export interface ICurrencyOrder {
    [key: string]: number;
}

class Currency extends React.PureComponent<IProps, any> {
    public static metricIconName: ITypes = {
        exp: 'silver-star',
        damage: 'damage',
        ribbons_count: 'ribbon',
    };

    public static order: ICurrencyOrder = {
        steel: 4,
        coal: 3,
        dublon: 2,
        gold: 2,
        oil: 1,
    };

    public static names: INames = {
        exp: 'exp',
        'free-exp': 'free-exp',
        gold: t('Дублоны'),
        credits: 'credits',
        dublon: t('Дублоны'),
        oil: t('Нефть'),
        coal: t('Уголь'),
        steel: t('Сталь'),
        'silver-star': 'silver-star',
        'gold-star': 'gold-star',
        ribbon: t('Ленты'),
        damage: t('Урон'),
        eventum: 'eventum',
        'clan-tag': 'clan-tag',
        'clan-rank': 'clan-rank',
        'elite-xp': 'elite-xp',
    };

    public static types: ITypes = {
        exp: 'exp',
        'free-exp': 'free-exp',
        gold: 'dublon',
        credits: 'credits',
        dublon: 'dublon',
        oil: 'oil',
        coal: 'coal',
        steel: 'steel',
        'silver-star': 'silver-star',
        'gold-star': 'gold-star',
        ribbon: 'ribbon',
        damage: 'damage',
        'cwars-star': 'cwars-star',
        eventum: 'eventum',
        'clan-tag': 'clan-tag',
        'clan-rank': 'clan-rank',
        'elite-xp': 'elite-xp',
    };

    getAmount = () => {
        return `${this.props.amount}`
            .split('/')
            .map((part) => thousands(part))
            .join('/');
    };

    public render() {
        if (!Currency.types[this.props.type]) {
            return null;
        }
        const style: React.CSSProperties = {};
        if (this.props.color) {
            style.color = this.props.color;
        }
        return (
            <div
                style={style}
                className={classNames(
                    styles.currency,
                    styles[this.props.type],
                    {
                        [styles.isBold]: !!this.props.isBold,
                        [styles.withMargin]: !!this.props.withMargin,
                        [styles.isFlat]: !!this.props.isFlat,
                    },
                    this.props.className,
                )}
            >
                {!!this.props.onlyIcon ? null : this.getAmount()}
            </div>
        );
    }
}

export default Currency;
