import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Table.module.scss';

interface IProps extends ComponentProps {
    children: React.ReactNode;
    modify: 'check' | 'small' | 'middle' | 'basis' | 'left' | 'right' | 'center' | 'buttons' | 'role';
    isSortable?: boolean;
    isActive?: boolean;
    isHidden?: boolean;
    tooltipParam?: string;
    tooltipText?: string;
    align?: string;
    onClick?: () => void;
}

class TableHeadCell extends React.PureComponent<IProps> {
    public render() {
        const classNameTh = classNames(
            styles.th,
            styles[this.props.modify],
            {
                [styles.hide]: this.props.isHidden,
            },
            this.props.className,
        );

        const classNameSorter = classNames(styles.sorter, {
            [styles.isSortable]: this.props.isSortable,
            [styles.isActive]: this.props.isActive,
        });

        return (
            <div className={classNameTh} style={this.props.onClick ? {} : { alignItems: 'center' }}>
                {this.props.onClick ? (
                    <div
                        className={classNameSorter}
                        onClick={this.props.onClick}
                        style={this.props.align ? { justifyContent: `${this.props.align}` } : {}}
                    >
                        {this.props.children}
                    </div>
                ) : (
                    this.props.children
                )}
            </div>
        );
    }
}

export default TableHeadCell;
