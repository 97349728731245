import { getEpicStoreAccountId } from '@/platform/EpicGamesStore/getEpicStoreAccountId';
import { queryEpicStoreOffers } from '@/platform/EpicGamesStore/queryEpicStoreOffers';
import { startEpicStoreCheckout } from '@/platform/EpicGamesStore/startEpicStoreCheckout';


export default {
  getEpicStoreAccountId,
  queryEpicStoreOffers,
  startEpicStoreCheckout,
};
