import classnames from 'classnames';
import * as React from 'react';
import { CSSProperties } from 'react';

import { ComponentProps } from '@/components/Component';

import Beacon from '../Beacon/Beacon';
import Divider from '../Divider/Divider';
import styles from './Popover.module.scss';

export type PopoverPosition = 'top' | 'right' | 'bottom' | 'left';

export interface Popover_Props extends ComponentProps {
    position: PopoverPosition;
    isVisible: boolean;
    header?: React.ReactNode;
    content?: React.ReactNode;
    withBeacon?: boolean;
    blockClassName?: string;
    onClose: () => void;
    onClick?: (event: React.MouseEvent) => void;
}

export interface Popover_State {
    size?: DOMRect;
    isOpaque: boolean;
}

export default class Popover extends React.PureComponent<Popover_Props, Popover_State> {
    private popover: HTMLDivElement | null;
    private spacer = 10;
    private interval: number;

    state: Popover_State = {
        size: undefined,
        isOpaque: false,
    };

    setPopoverRef = (r: HTMLDivElement) => {
        this.popover = r;
        this.updateSize();
    };

    public componentDidMount() {
        this.interval = setInterval(this.updateSize, 50);
    }

    updateSize = () => {
        if (this.popover) {
            const rect = this.popover.getBoundingClientRect();
            if (!this.state.size || this.state.size.width !== rect.width || this.state.size.height !== rect.height) {
                this.setState(
                    {
                        size: rect,
                    },
                    () => {
                        setTimeout(() => {
                            if (
                                this.state.size?.width === rect.width &&
                                this.state.size?.height === rect.height &&
                                !this.state.isOpaque
                            ) {
                                this.setState({
                                    isOpaque: true,
                                });
                            }
                        }, 80);
                    },
                );
            }
        }
    };

    public componentWillUnmount() {
        clearInterval(this.interval);
    }

    getPopoverStyle = () => {
        if (!this.state.size) {
            return undefined;
        }
        switch (this.props.position) {
            case 'top': {
                return {
                    top: `-${this.state.size.height + this.spacer}px`,
                    left: '50%',
                    marginLeft: `-${this.state.size.width / 2}px`,
                };
            }
            case 'right': {
                return {
                    top: '50%',
                    right: `-${this.state.size.width + this.spacer}px`,
                    marginTop: `-${this.state.size.height / 2}px`,
                };
            }

            case 'bottom': {
                return {
                    bottom: `-${this.state.size.height + this.spacer}px`,
                    left: '50%',
                    marginLeft: `-${this.state.size.width / 2}px`,
                };
            }
            case 'left': {
                return {
                    top: '50%',
                    left: `-${this.state.size.width + this.spacer}px`,
                    marginTop: `-${this.state.size.height / 2}px`,
                };
            }
            default: {
                return undefined;
            }
        }
    };

    getBeaconPosition = (): CSSProperties => {
        const s = this.spacer + 25;
        const styles: Record<PopoverPosition, CSSProperties> = {
            left: { right: `-${s}px`, top: '50%', transform: 'translateY(-50%)' },
            right: { left: `-${s}px`, top: '50%', transform: 'translateY(-50%)' },
            top: { left: '50%', bottom: `-${s}px`, transform: 'translateX(-50%)' },
            bottom: { left: '50%', top: `-${s}px`, transform: 'translateX(-50%)' },
        };
        return { position: 'absolute', ...styles[this.props.position] };
    };

    getBackground = () => {
        const o = parseInt(styles.o) || 10;
        const w = this.state.size ? this.state.size.width : 0;
        const h = this.state.size ? this.state.size.height : 0;

        const wc = w / 2;
        const hc = h / 2;
        const sw = 1;
        const swh = sw / 2;
        let points = '';
        switch (this.props.position) {
            case 'top': {
                points = `${swh},${swh} ${w - swh},${swh} ${w - swh},${h - o - swh} ${wc + o},${h - o - swh} ${wc},${
                    h - swh
                } ${wc - o},${h - o - swh} ${swh},${h - o - swh}`;
                break;
            }
            case 'right': {
                points = `${swh + o},${swh} ${w - swh},${swh} ${w - swh},${h - swh} ${swh + o},${h - swh} ${swh + o},${
                    hc + o
                } ${swh},${hc} ${swh + o},${hc - o}`;
                break;
            }
            case 'bottom': {
                points = `${swh},${swh + o} ${wc - o},${swh + o} ${wc},${swh} ${wc + o},${swh + o} ${w - swh},${
                    swh + o
                } ${w - swh},${h - swh} ${swh},${h - swh}`;
                break;
            }
            case 'left': {
                points = `${swh},${swh} ${w - swh - o},${swh} ${w - swh - o},${hc - o} ${w - swh},${hc} ${
                    w - swh - o
                },${hc + o}  ${w - swh - o},${h - swh} ${swh},${h - swh}`;
                break;
            }
            default: {
                break;
            }
        }
        return (
            <div className={styles.background}>
                <svg width={'100%'} height={'100%'} viewBox={`0 0 ${w} ${h}`}>
                    <polygon
                        className={styles.polygon}
                        fill="none"
                        stroke={'rgba(255,204,102,0.7)'}
                        strokeWidth={sw}
                        points={points}
                    />
                </svg>
            </div>
        );
    };

    render() {
        const wrapper = classnames(styles.wrapper, this.props.className, styles[`position-${this.props.position}`]);
        const popover = classnames(styles.popover, {
            [styles.invisible]: !this.state.isOpaque,
        });

        return (
            <div className={wrapper} onClick={this.props.onClick}>
                {this.props.children}
                {this.props.isVisible ? (
                    <div className={popover} style={this.getPopoverStyle()} ref={this.setPopoverRef}>
                        {!!this.props.withBeacon && <Beacon style={this.getBeaconPosition()} className={styles.beacon} />}
                        <div className={classnames(styles.inner, this.props.blockClassName || null)}>
                            {this.getBackground()}
                            {this.props.header ? (
                                <React.Fragment>
                                    <div className={styles.header}>
                                        {this.props.header}
                                        <div className={styles.close} onClick={this.props.onClose} />
                                    </div>
                                    <Divider type={'major_light'} />
                                </React.Fragment>
                            ) : null}
                            {this.props.content ? <div className={styles.content}>{this.props.content}</div> : null}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
