import { EVENTS } from '@/constants/events';
import { RESTRICTION_STATUTES, restrictionStatus } from '@/constants/restrictionStatus';
import { ProductEntity } from '@/core/entity/product.entity';
import { UserEntity } from '@/core/entity/user.entity';
import { EventEmitter } from '@/core/event';

import App from './App.svelte';

export default function (product: ProductEntity, events: EventEmitter, user: UserEntity): Promise<restrictionStatus> {
  return new Promise((resolve) => {
    events.emit(EVENTS.OVERLAY_VISIBILITY, { visible: true });

    const app = new App({
      target: document.body,
      props: {
        closePopup,
        continuePurchase,
        product,
        user,
      },
    });

    function destroy() {
      app.$destroy();
      events.emit(EVENTS.OVERLAY_VISIBILITY, { visible: false });
    }

    function closePopup() {
      destroy();
      resolve(RESTRICTION_STATUTES.CANCELLED);
    }

    function continuePurchase() {
      destroy();
      resolve(RESTRICTION_STATUTES.SUCCESS);
    }
  });
}
