import classNames from 'classnames';
import * as React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import { ComponentProps } from '@/components/Component';
import { t } from '~/helpers/localization';

import Collapse from '../Collapse/Collapse';
import Divider from '../Divider/Divider';
import Toggle from '../Toggle/Toggle';
import styles from './Description.module.scss';

const MAX_LINES_COUNT = 5;
const LINE_HEIGHT = 20;
const MAX_HEIGHT_DESCRIPTION = LINE_HEIGHT * MAX_LINES_COUNT;
const fadeTimeout = 300;

export interface IProps extends ComponentProps {
    children: string;
}

export type IState = {
    isToggle: boolean;
    isOpened: boolean;
    isCollapseAnimationEnd: boolean;
    isOverflowed: boolean;
};

class Description extends React.PureComponent<IProps, IState> {
    public _descriptionBlock: HTMLDivElement;

    constructor(props: IProps) {
        super(props);
        this.state = {
            isToggle: false,
            isOpened: false,
            isOverflowed: false,
            isCollapseAnimationEnd: true,
        };
    }

    public componentDidMount() {
        window?.addEventListener('resize', this.onResize);
        this.updateDescriptionToggleOverflowState(this.props.children);
    }

    public componentWillUnmount() {
        window?.removeEventListener('resize', this.onResize);
    }

    public UNSAFE_componentWillReceiveProps({ children }: IProps) {
        if (children !== this.props.children) {
            this.updateDescriptionToggleOverflowState(children);
        }
    }

    public onResize = () => {
        this.updateDescriptionToggleOverflowState(this.props.children);
    };

    public updateDescriptionToggleOverflowState(description: string) {
        const [isOverflowed, isToggle] = this.isNeedToggleAndDots(description);
        this.setState({
            isOverflowed,
            isToggle,
        });
    }

    public isNeedToggleAndDots(description: string) {
        let lines = description
            .replace(/<(\/?p|br\/?)>/g, '')
            .split('\n')
            .slice(0, -1);
        lines = lines.filter((line, index) => !index || line || lines[index - 1]);

        const width = this._descriptionBlock ? this._descriptionBlock.offsetWidth : 0;
        const styles: CSSStyleDeclaration = window?.getComputedStyle(this._descriptionBlock);
        const fontSize = styles ? styles.getPropertyValue('font-size') : '14px';
        const fontFamily = styles ? styles.getPropertyValue('font-family') : 'sans-serif';
        const span: HTMLSpanElement = document.createElement('span');

        document.body.appendChild(span);
        span.style.display = 'block';
        span.style.fontFamily = fontFamily;
        span.style.fontSize = fontSize;
        span.style.lineHeight = `${LINE_HEIGHT}px`;
        span.style.minHeight = `${LINE_HEIGHT}px`;
        span.style.visibility = 'hidden';
        span.style.whiteSpace = 'normal';
        span.style.width = `${width}px`;
        span.style.wordBreak = 'break-word';

        let actualLines = 0;
        let currentLines = 0;
        let needDots = false;

        for (let i = 0; i < MAX_LINES_COUNT; i++) {
            if (lines[i] === undefined) {
                break;
            }

            span.innerHTML = lines[i];
            currentLines = Math.ceil(span.offsetHeight / LINE_HEIGHT);
            actualLines += currentLines;

            if (actualLines > MAX_LINES_COUNT) {
                needDots = currentLines > 1;
                break;
            }
        }

        span.innerHTML = description;
        const needToggle = span && span.offsetHeight ? span.offsetHeight > MAX_HEIGHT_DESCRIPTION : false;

        document.body.removeChild(span);
        return [needDots, needToggle];
    }

    public onClick = () => {
        this.setState({
            isOpened: !this.state.isOpened,
            isCollapseAnimationEnd: false,
        });
    };

    public renderToggleButton() {
        if (this.state.isToggle) {
            let content = null;
            if (this.state.isCollapseAnimationEnd) {
                content = (
                    <ReactCSSTransitionGroup
                        transitionName="fade"
                        transitionAppear
                        transitionEnter={false}
                        transitionLeaveTimeout={fadeTimeout}
                        transitionAppearTimeout={fadeTimeout}
                    >
                        <Toggle
                            key="clan-description-toggle"
                            caption={this.state.isOpened ? t('Свернуть описание') : t('Развернуть описание')}
                            onClick={this.onClick}
                        />
                    </ReactCSSTransitionGroup>
                );
            }

            return <div className={styles.toggle}>{content}</div>;
        }
        return null;
    }

    public renderDivider = () => {
        return <Divider type="major" />;
    };

    public render() {
        const classNameTextDescription = classNames(styles.text, this.props.className, {
            [styles.isOpened]: this.state.isOpened,
            [styles.isOverflowed]: this.state.isOverflowed,
            [styles.isToggle]: this.state.isToggle,
        });

        return (
            <div className={styles.description}>
                {this.renderDivider()}
                <div className={styles.inner}>
                    <Collapse
                        onRest={() => this.setState({ isCollapseAnimationEnd: true })}
                        defaultHeight={MAX_HEIGHT_DESCRIPTION}
                        isOpened={this.state.isOpened}
                    >
                        <div
                            ref={(el) => {
                                if (el) {
                                    this._descriptionBlock = el;
                                }
                            }}
                            className={classNameTextDescription}
                            dangerouslySetInnerHTML={{ __html: this.props.children }}
                        />
                    </Collapse>
                    {this.renderToggleButton()}
                </div>
                {this.renderDivider()}
            </div>
        );
    }
}

export default Description;
