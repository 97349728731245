import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import DivTooltip from '../DivTooltip/DivTooltip';
import Tooltip from '../Tooltip/Tooltip';
import TooltipBody from '../Tooltip/TooltipBody';
import TooltipHeader from '../Tooltip/TooltipHeader';
import styles from './HelperIcon.module.scss';
import Icon from './Icon';

export interface IProps extends ComponentProps {
    header: string;
    children: React.ReactNode;
    isStatic?: boolean;
    position?: string;
    fixed?: boolean;
}

class HelperIcon extends React.PureComponent<IProps> {
    public render() {
        return (
            <div className={classNames(styles.container, this.props.className)}>
                <DivTooltip
                    tooltipBody={
                        <Tooltip>
                            <TooltipHeader isBold={false} isHelpIcon>
                                {this.props.header}
                            </TooltipHeader>
                            <TooltipBody>{this.props.children}</TooltipBody>
                        </Tooltip>
                    }
                >
                    <Icon />
                </DivTooltip>
            </div>
        );
    }
}

export default HelperIcon;
