import { AsyncResponse, sendAsyncAction } from '@/core/async';


export interface EpicStoreCheckout {
  transactionId: string,
}

export function startEpicStoreCheckout(offerId: string): Promise<AsyncResponse<EpicStoreCheckout>> {
  return sendAsyncAction({
    actionId: 'startEpicStoreCheckout',
    offerId,
  });
}
