import classnames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './ProgressCheckboxStyles.module.scss';

export interface IProgressCheckbox extends ComponentProps {
    isReady?: boolean;
    needSpacer?: boolean;
    anchorIcon?: boolean;
    onClick: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export interface IProgressCheckboxState {
    topOffset: number;
    delay: number;
    isUpdating: boolean;
}

class ProgressCheckbox extends React.PureComponent<IProgressCheckbox, IProgressCheckboxState> {
    public interval: any;

    constructor(props: IProgressCheckbox) {
        super(props);

        this.state = {
            topOffset: 60,
            delay: 4,
            isUpdating: false,
        };
    }

    public componentDidMount() {
        if (this.props.isReady) {
            this._ready();
        } else {
            this._start();
        }
    }

    public _start = () => {
        this.interval = setInterval(() => {
            if (this.state.topOffset !== 0) {
                this.setState({
                    topOffset: this.state.topOffset - 1,
                    isUpdating: true,
                });
            }
        }, 120);
    };

    public _ready = () => {
        if (this.interval) {
            clearInterval(this.interval);
        }

        this.setState({
            topOffset: 0,
            delay: 0,
        });
    };

    public UNSAFE_componentWillReceiveProps(nextProps: IProgressCheckbox) {
        if (nextProps.isReady) {
            this._ready();
        }
    }

    public render() {
        if (!this.state.isUpdating && !this.props.isReady) {
            this._start();
        }

        return (
            <div
                className={classnames(styles.container, this.props.className, {
                    [styles.ready]: this.props.isReady,
                    [styles.anchorIcon]: this.props.anchorIcon && this.props.isReady,
                })}
                onClick={this.props.onClick}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
            >
                <div className={styles.spacer} />
                <div
                    className={styles.levelProgress}
                    style={{
                        transform: `translateY(${this.state.topOffset}px)`,
                    }}
                ></div>
            </div>
        );
    }
}

export default ProgressCheckbox;
