import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Select.module.scss';

export interface ISelect {
    name: string;
    value: string;
}

export interface IProps extends ComponentProps {
    items: Array<ISelect>;
    itemValueSelected: string;
    onItemChange: (str: string) => void;
}

export interface IState {
    isActive: boolean;
    itemSelected: ISelect;
}

class Select extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const itemSelected = this.props.items.find((obj) => obj.value === this.props.itemValueSelected);

        if (itemSelected) {
            this.state = {
                isActive: false,
                itemSelected: {
                    name: itemSelected.name,
                    value: itemSelected.value,
                },
            };
        }
    }

    public componentDidMount() {
        const itemSelected: any = this.props.items.find((obj) => obj.value === this.props.itemValueSelected);

        if (itemSelected) {
            this.setState((prevState) => ({
                isActive: false,
                itemSelected: {
                    name: itemSelected.name,
                    value: itemSelected.value,
                },
            }));
        }
        document.addEventListener('keydown', this.onEscape, true);
    }

    public static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        const itemSelected = nextProps.items.find((obj) => obj.value === nextProps.itemValueSelected);

        return {
            itemSelected,
        };
    }

    public componentWillUnmount() {
        document.removeEventListener('keydown', this.onEscape, true);
    }

    public hide() {
        this.setState({ isActive: false });
    }

    public onToggle = () => {
        this.setState({ isActive: !this.state.isActive });
    };

    public onBlur = () => {
        this.hide();
    };

    public onEscape = (e: KeyboardEvent) => {
        if (e.keyCode === 27 && this.state.isActive) {
            e.preventDefault();
            this.hide();
        }
    };

    public onItemChange = (itemSelected: ISelect) => {
        this.setState({ itemSelected });
        this.props.onItemChange(itemSelected.value);
        this.hide();
    };

    public render() {
        const classNameSelect = classNames(styles.select, {
            [styles.isActive]: this.state.isActive,
        });

        const classNameDropdown = classNames(styles.dropdown, {
            [styles.isDropped]: this.state.isActive,
        });

        const selectItems = this.props.items.map((item, index) => {
            const classNameItem = classNames(styles.item, {
                [styles.isSelected]: this.state.itemSelected.value === item.value,
            });

            return (
                <li
                    className={classNameItem}
                    key={index}
                    onClick={() => {
                        this.onItemChange(item);
                    }}
                >
                    {item.name}
                </li>
            );
        });

        return (
            <div className={classNames(styles.container, this.props.className)} tabIndex={1} onBlur={this.onBlur}>
                <div className={classNameSelect} onClick={this.onToggle}>
                    {this.state.itemSelected.name}
                </div>
                <menu className={classNameDropdown}>{selectItems}</menu>
            </div>
        );
    }
}

export default Select;
