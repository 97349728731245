import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import ClanRole from '../ClanRole/ClanRole';
import styles from './MemberName.module.scss';

export interface IProps extends ComponentProps {
    role?: string;
    name: string;
    isInline?: boolean;
}

const MemberName = React.memo((props: IProps) => {
    const classNameMember = classNames(styles.member, props.className, {
        [styles.isInline]: props.isInline,
    });

    const role = props.role ? <ClanRole key="member_name_role" role={props.role} /> : null;

    return (
        <span className={classNameMember}>
            {role}
            <span key="member_name" className={styles.name}>
                {props.name}
            </span>
        </span>
    );
});

export default MemberName;
