import { t } from '@/i18n';

export const PURCHASE_ERROR_CODES = {
  TSV_INVALID_OTP_CODE: 'TSV_INVALID_OTP_CODE',
  TSV_OTP_CODE_EXPIRED: 'TSV_OTP_CODE_EXPIRED',
  USER_HAS_INSUFFICIENT_FUNDS: 'USER_HAS_INSUFFICIENT_FUNDS',
  PAYMENT_GATEWAY_PAYMENT_PROCESSING_ERROR: 'PAYMENT_GATEWAY_PAYMENT_PROCESSING_ERROR',
  PAYMENT_METHODS_NOT_FOUND: 'PAYMENT_METHODS_NOT_FOUND',
  PURCHASE_FORBIDDEN_BY_COUNTRY_RESTRICTION: 'PURCHASE_FORBIDDEN_BY_COUNTRY_RESTRICTION',
  PURCHASER_BANNED_BY_SANCTION_RESTRICTION: 'PURCHASER_BANNED_BY_SANCTION_RESTRICTION',
  ACCOUNT_COUNTRY_NOT_SPECIFIED: 'ACCOUNT_COUNTRY_NOT_SPECIFIED',
  ACCOUNT_EMAIL_NOT_SPECIFIED: 'ACCOUNT_EMAIL_NOT_SPECIFIED',
  ADDRESS_REQUIRED_BUT_NOT_EXIST: 'ADDRESS_REQUIRED_BUT_NOT_EXIST',
  USER_PROFILE_NOT_COMPLETED: 'USER_PROFILE_NOT_COMPLETED',
};

export const DEPENDS_ON_REALM = [PURCHASE_ERROR_CODES.PURCHASE_FORBIDDEN_BY_COUNTRY_RESTRICTION];

export const PURCHASE_ERROR_CODES_TEXT = {
  [PURCHASE_ERROR_CODES.TSV_INVALID_OTP_CODE]: t('Введен неверный код'),
  [PURCHASE_ERROR_CODES.TSV_OTP_CODE_EXPIRED]: t('Время для ввода кода истекло. Необходимо использовать новый код.'),
  [PURCHASE_ERROR_CODES.USER_HAS_INSUFFICIENT_FUNDS]: t('Недостаточно средств'),
  [PURCHASE_ERROR_CODES.PAYMENT_GATEWAY_PAYMENT_PROCESSING_ERROR]: t('Недостаточно средств'),
  [PURCHASE_ERROR_CODES.PAYMENT_METHODS_NOT_FOUND]: t('Платежные методы недоступны для вашего региона.'),
  [PURCHASE_ERROR_CODES.PURCHASE_FORBIDDEN_BY_COUNTRY_RESTRICTION]: t(
    'В Wargaming недоступны методы оплаты для игроков из Российской Федерации и Республики Беларусь. Если ваша страна определена некорректно, пожалуйста, обратитесь в {link-open}службу поддержки пользователей.{link-close}',
  ),
  [PURCHASE_ERROR_CODES.PURCHASER_BANNED_BY_SANCTION_RESTRICTION]: t(
    'Покупка не может быть совершена из-за ограничений на вашем аккаунте. Обратитесь в {link-open}службу поддержки{link-close}',
  ),
  [PURCHASE_ERROR_CODES.ADDRESS_REQUIRED_BUT_NOT_EXIST]: t(
    'Для совершения покупок необходимо указать адрес для выставления счёта',
  ),
  [PURCHASE_ERROR_CODES.ACCOUNT_EMAIL_NOT_SPECIFIED]: t(
    'Необходимо дополнить данные учётной записи. Пожалуйста, воспользуйтесь Формой дополнения аккаунта.',
  ),
  [PURCHASE_ERROR_CODES.ACCOUNT_COUNTRY_NOT_SPECIFIED]: t(
    'Необходимо дополнить данные учётной записи, обратитесь в {link-open}службу поддержки пользователей.{link-close}',
  ),
  [PURCHASE_ERROR_CODES.USER_PROFILE_NOT_COMPLETED]: t(
    'Необходимо дополнить данные учётной записи, обратитесь в {link-open}службу поддержки пользователей.{link-close}',
  ),
};

export const PURCHASE_ERROR_CODES_TEXT_FOR_CIS = {
  [PURCHASE_ERROR_CODES.PURCHASE_FORBIDDEN_BY_COUNTRY_RESTRICTION]: t(
    'В Lesta Games доступны методы оплаты только для игроков из Российской Федерации и Республики Беларусь. Если ваша страна определена некорректно, пожалуйста, обратитесь в {link-open}службу поддержки пользователей.{link-close}',
  ),
};

export const PURCHASE_ERRORS_BY_COUNTRY = [
  PURCHASE_ERROR_CODES.PURCHASE_FORBIDDEN_BY_COUNTRY_RESTRICTION,
  PURCHASE_ERROR_CODES.PURCHASER_BANNED_BY_SANCTION_RESTRICTION,
  PURCHASE_ERROR_CODES.ACCOUNT_COUNTRY_NOT_SPECIFIED,
  PURCHASE_ERROR_CODES.USER_PROFILE_NOT_COMPLETED,
];
