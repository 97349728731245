import { sendAction } from '@/core/action';
import { log } from '@/utils/logger';
import WinStoreCallbackObserver, { WinStoreCallbacks } from '@/platform/WinStore/WinStoreCallbackObserver';


export type WinStoreUserCountry = string;

export function getUserCountry(): Promise<WinStoreUserCountry> {
  return new Promise((resolve) => {
    WinStoreCallbackObserver.once(WinStoreCallbacks.OnUserCountryReceived, (country) => {
      log('Resolve getUserCountry method', country);

      resolve(country);
    });

    sendAction({
      actionId: 'getUserCountry',
    });
  });
}
