import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Content.module.scss';

export interface IProps extends ComponentProps {
    children: React.ReactNode;
}

const Content = React.memo(({ children, className }: IProps) => (
    <div className={classNames(styles.content, className)}>{children}</div>
));

export default Content;
