import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Table.module.scss';

export interface IProps extends ComponentProps {
    children: React.ReactNode;
}

const TableBody = (props: IProps) => {
    const className = classNames(styles.tbody, props.className);
    return <div className={className}>{props.children}</div>;
};

export default TableBody;
