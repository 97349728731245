import web2clientSounds from '@wg/web2clientapi/sound';
import classNames from 'classnames';
import * as React from 'react';
import { useCallback } from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Radio.module.scss';

export interface IRadio extends ComponentProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isChecked?: boolean;
    isDisabled?: boolean;
    name: string;
    children: React.ReactChild;
    value: string | string[] | number;
}

const Radio = React.memo((props: IRadio) => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            web2clientSounds.playCheckboxSound();
            if (props.onChange) props.onChange(e);
        },
        [props.onChange],
    );

    return (
        <label className={classNames(styles.label, props.className)}>
            <input
                className={styles.input}
                type="radio"
                name={props.name}
                value={props.value}
                checked={props.isChecked}
                disabled={props.isDisabled}
                onChange={handleChange}
            />
            <span className={styles.radio} />
            <div className={styles.caption}>{props.children}</div>
        </label>
    );
});

export default Radio;
