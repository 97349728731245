import classnames from 'classnames';
import * as React from 'react';
import { CSSProperties } from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Beacon.module.scss';

export interface IBeaconProps extends ComponentProps {
    style?: CSSProperties;
}

const Beacon: React.FC<IBeaconProps> = ({ style, className }): React.ReactElement => {
    return (
        <div className={classnames(styles.wrapper, className)} style={style}>
            <div className={styles.beacon} id={'beacon'} />
        </div>
    );
};

export default React.memo(Beacon);
