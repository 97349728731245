import { PureComponent } from 'react';
import isEqual from 'react-fast-compare';
export interface ComponentProps {
    className?: string;
}

export class DeepPureComponent<P = Record<string, any>, S = Record<string, any>, SS = any> extends PureComponent<
    P,
    S,
    SS
> {
    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>): boolean {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }
}
