import { sendAction } from '@/core/action';
import { closeBrowser } from './closeBrowser';


export function openBattlePass() {
  sendAction({
    actionId: 'openBattlePass',
  });
  closeBrowser();
}
