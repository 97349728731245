import { CommitBrowserInfo } from '@/api/platform/web';

export function collectBrowserInfo(): CommitBrowserInfo {
  return {
    colorDepth: window.screen.colorDepth,
    javaEnabled: window.navigator?.javaEnabled?.() || false,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    timeZineOffset: new Date().getTimezoneOffset(),
  };
}
