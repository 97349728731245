import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './Table.module.scss';

export interface IProps extends ComponentProps {
    children: React.ReactNode;
    isActive?: boolean;
    isHovering?: boolean;
    isOwn?: boolean;
    isDisabled?: boolean;
    onContextMenu?: () => void;
    onClick?: () => void;
}

const defaultProps = {
    isActive: false,
    isHovering: false,
    isOwn: false,
    isDisabled: false,
};

const TableRow = (props: IProps) => {
    const classNameTr = classNames(
        styles.tr,
        {
            [styles.isActive]: !props.isDisabled ? props.isActive : false,
            [styles.isHovering]: !props.isDisabled ? props.isHovering : false,
            [styles.isOwn]: props.isOwn,
            [styles.isDisabled]: props.isDisabled,
        },
        props.className,
    );

    return (
        <div
            className={classNameTr}
            id={props.isOwn ? 'ownScrollId' : undefined}
            onClick={!props.isDisabled ? props.onClick : () => null}
            onContextMenu={props.onContextMenu}
        >
            {props.children}
        </div>
    );
};

TableRow.defaultProps = defaultProps;

export default TableRow;
