import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import styles from './PageTitle.module.scss';

export interface IProps extends ComponentProps {
    title: string | React.ReactNode;
    subTitle: string;
}

class PageTitle extends React.PureComponent<IProps> {
    public render() {
        const subTitle = this.props.subTitle ? <div className={styles.subTitle}>{this.props.subTitle}</div> : null;

        return (
            <div className={classNames(styles.container, this.props.className)}>
                <div className={styles.title}>{this.props.title}</div>
                {subTitle}
            </div>
        );
    }
}

export default PageTitle;
