import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';
import { toRoman } from '~/helpers/formatting';
import { getClassClanLeverWrapper } from '~/helpers/supply';

import styles from './ClanLevel.module.scss';

export interface IProps extends ComponentProps {
    clanLevel: number;
}

export class ClanLevel extends React.PureComponent<IProps, any> {
    public render() {
        const classClanLeverWrapper = getClassClanLeverWrapper(this.props.clanLevel);
        const classNameBase = styles[`clan-level-wrapper-${classClanLeverWrapper}`];

        return (
            <div className={classNames(classNameBase, this.props.className)}>
                <div className={styles.levelRomain}>{toRoman(this.props.clanLevel)}</div>
            </div>
        );
    }
}

export default ClanLevel;
